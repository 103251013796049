import styled from 'styled-components';

export const Wrapper = styled.div`
    h5 {
        margin-left: 4px;
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        h5 {
            text-align: center;
        }
    }
`;
