import styled from 'styled-components';
import Button from 'antd/lib/button';

export const AppointmentDateTime = styled.div`
    h3 {
        color: ${({ theme }) => theme.primary6};
        line-height: 24px;
        margin: 0 !important;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 32px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        h4 {
            line-height: 16px;
            margin-bottom: 4px;
        }
        h3 {
            font-size: 18px;
        }
    }
`;

export const AppointmentDuration = styled.div`
    margin-top: 20px;

    h4 {
        margin: 0;
        line-height: 32px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin: 12px 0 11px;
    }
`;

export const ButtonStyled = styled(Button)`
    &.ant-btn {
        height: initial !important;
        padding: 0;
        margin-top: 5px;

        span {
            margin: 0 5px;
        }
    }
`;
