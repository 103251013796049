import styled from 'styled-components';

export const GoogleMapWrapper = styled.div`
    position: fixed;
    left: 50vw;
    right: 50px;
    top: 255px;
    bottom: 50px;
    margin-left: 16px;

    div[class=''] {
        margin-bottom: 7px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        position: inherit;
        width: 100%;
        height: 50vh;
        margin-top: 16px;
        margin-left: 0;
    }
`;
