import React from 'react';
import { useSelector } from 'react-redux';

import {
    getClosestEntity,
    selectClosestEntityLoading,
    selectEntitiesList,
    selectEntitiesLoading,
    selectEntityLoading,
    selectEntityResults,
} from 'redux/selectors';
import { entitiesActions } from 'redux/slices';
import { useActions } from 'hooks';
import type { Entity } from 'models';

import { ListStyled } from './EntityList.styled';
import { EntityItem } from '../EntityItem';

interface EntityListProps {
    center?: google.maps.LatLngLiteral;
    handleClickReturn: () => void;
}

export const EntityList: React.FC<EntityListProps> = () => {
    const { setEntityIsChosen } = useActions(entitiesActions);

    const closestEntities = useSelector(getClosestEntity);
    const loadingClosestEntity = useSelector(selectClosestEntityLoading);
    const loadingEntity = useSelector(selectEntityLoading);
    const loadingEntities = useSelector(selectEntitiesLoading);
    const results = useSelector(selectEntityResults);
    const list = useSelector(selectEntitiesList);

    const loading = loadingEntities || loadingClosestEntity || loadingEntity;

    return (
        <ListStyled
            loading={loading}
            data-isallentities={!results}
            onMouseLeave={() => setEntityIsChosen(undefined)}
            itemLayout="horizontal"
            dataSource={!!results ? closestEntities : list}
            renderItem={(entity: Entity, index: number) => <EntityItem entity={entity} index={index} />}
        />
    );
};
