export const wyndTheme = {
    /** Neutral Colors (text & containers) **/
    neutral1: '#FFFFFF',
    neutral2: '#F8F9F9',
    neutral3: '#E9EAEC',
    neutral4: '#D2D4DA',
    neutral5: '#B1B4BE',
    neutral6: '#8F94A1',
    neutral7: '#676E80',
    neutral8: '#444E66',
    neutral9: '#1F2944',
    /** Primary Colors (highlights) **/
    primary1: '#EEF1F7',
    primary2: '#DDE3F0',
    primary4: '#8F94A1',
    primary5: '#4A5F97',
    primary6: '#5873B5',
    primary8: '#394A75',
    primary9: '#283452',
    primary10: '#829CD7',
    /** Secondary Colors (actions) **/
    secondary1: '#EBF8FE',
    secondary2: '#D7F1FD',
    secondary3: '#C3E9FD',
    secondary5: '#6BCAFA',
    secondary6: '#3BB9F9',
    secondary7: '#329DD3',
    secondary8: '#08A7F7',
    secondary9: '#114D98',
    /** System Colors (informations) **/
    valid1: '#EEF9EE',
    valid2: '#DDF3DE',
    valid3: '#ECF9EC',
    valid4: '#7DD380',
    valid6: '#56C65A',
    valid7: '#48A64B',
    valid8: '#3BAF3F',
    valid9: '#2E8932',
    error1: '#FCECEC',
    error2: '#FCE8E9',
    error3: '#FAD8D9',
    error4: '#F3A3A4',
    error5: '#EC6A6C',
    error6: '#E63F41',
    error7: '#C13536',
    error8: '#D41C1E',
    error9: '#A71617',
    warning1: '#FFF2E6',
    warning2: '#FFF3E0',
    warning3: '#FFE5CC',
    warning5: '#FF9D00',
    warning6: '#FF8100',
    warning7: '#D66C00',
    warning9: '#995E00',
    /** Pallette Wynd **/
    logan1: '#F5F4F8',
    logan2: '#ECEAF2',
    logan6: '#A197BF',
    logan7: '#877EA0',
    logan9: '#8477AB',
    moonstone1: '#F0F6F8',
    moonstone2: '#E2EDF1',
    moonstone6: '#6FA8BC',
    moonstone7: '#5D8C9D',
    moonstone9: '#4F93AA',
    cadet1: '#F5F7F9',
    cadet2: '#ECEEF3',
    cadet6: '#A3AEC3',
    cadet7: '#8992A3',
    cadet9: '#8392AE',
    sheen1: '#F0F9F5',
    sheen2: '#E2F4EC',
    sheen6: '#6FCAA1',
    sheen7: '#5DA987',
    sheen9: '#48BC88',
    diSerria1: '#FDF3ED',
    diSerria2: '#FCE9DD',
    diSerria6: '#F49258',
    diSerria7: '#CC7949',
    diSerria9: '#F17228',
    martini1: '#F7F5F5',
    martini2: '#EFECEA',
    martini6: '#B2A19A',
    martini7: '#958781',
    martini9: '#9C867D',
    sweetCorn1: '#FFFDF1',
    sweetCorn2: '#FFFBE4',
    sweetCorn6: '#FFEC7A',
    sweetCorn7: '#D6C566',
    sweetCorn9: '#FFE547',
    lily1: '#FBF5F8',
    lily2: '#F8ECF1',
    lily6: '#DEA1BB',
    lily7: '#BA879C',
    lily9: '#D17B9F',
    nadeashiko1: '#FFF6F8',
    nadeashiko2: '#FFEDF2',
    nadeashiko6: '#FFA6BF',
    nadeashiko7: '#D58B9F',
    nadeashiko9: '#FF759C',
    butterflyBush1: '#F1EFF6',
    butterflyBush6: '#645394',
    butterflyBush9: '#4D4072',
    violette1: '#EBEDF7',
    violette2: '#D8DBEF',
    violette6: '#3C4EB2',
    violette7: '#314195',
    violette9: '#303E8D',
    payne1: '#EFF0F2',
    payne2: '#DFE1E6',
    payne6: '#606C84',
    payne7: '#505B6F',
    payne9: '#4B5567',
    mediumSea1: '#E9F6F1',
    mediumSea2: '#D5EFE3',
    mediumSea6: '#31B076',
    mediumSea7: '#299363',
    mediumSea9: '#26885B',
    deepCarot1: '#FFEFE5',
    deepCarot6: '#FF5B00',
    deepCarot9: '#CC4900',
    milkyChoco1: '#F1EDEC',
    milkyChoco2: '#E4DDDA',
    milkyChoco6: '#795548',
    milkyChoco7: '#65473C',
    milkyChoco9: '#5A3F35',
    energy1: '#FDFBE6',
    energy2: '#FCF8CC',
    energy6: '#F2E000',
    energy7: '#CBBB02',
    energy9: '#BDAF00',
    lipstick1: '#F6E7ED',
    lipstick2: '#EED0DD',
    lipstick6: '#AD1457',
    lipstick7: '#901048',
    lipstick9: '#800F40',
    crayola1: '#FFE5ED',
    crayola6: '#E51D5A',
    crayola7: '#FF215E',
    crayola9: '#EB0040',
    royalBlue1: '#E8F1FC',
    royalBlue6: '#257BE7',
    amber1: '#FFF9E5',
    amber6: '#FFC000',
    /** Box shadows **/
    boxShadow1: 'inset 0px -1px 0px rgba(88, 115, 181, 0.1)',
    boxShadow2: '0 1px 3px 1px #ffffff',
    /** Screen sizes **/
    screenXl: '1600px',
    screenLg: '1200px',
    screenMd: '992px',
    screenSm: '768px',
    screenXs: '576px',
    /** Font sizes **/
    fontSizeLg: 32,
    fontSizeMd: 24,
    fontSizeSm: 20,
    fontSizeXs: 16,
    fontSizeXxs: 14,
    fontSizeXxxs: 12,
    /** Border radius **/
    borderRadius: 4,
};
