import Divider from 'antd/lib/divider';
import dayjs from 'dayjs';

import { AppointmentInfo, EntityHours, EntityInfo, CustomerComment, CustomerInfo, ConfirmModal } from 'components';
import { useActions, useLangNavigate } from 'hooks';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Icon } from '@wynd-bo/keystone-icons';

import { selectAppointmentDetails } from 'redux/selectors';
import { appointmentDetailsActions } from 'redux/slices';
import { BookingStatus } from 'models';
import { ROUTE_CHOOSE_STORE } from 'const';

import {
    AppointmentActions,
    AppointmentButton,
    CancelButton,
    EntityWrapper,
    ErrorText,
    IconError,
    IconSuccess,
    StyledSubtitle,
    StyledTitle,
    SuccessText,
    TitleWrapper,
    Wrapper,
} from './AppointmentDetails.styled';

export const AppointmentDetails: FC = () => {
    const { t } = useTranslation();
    const { bookingUuid } = useParams();
    const [searchParams] = useSearchParams();
    const [cancellationModalOpen, setCancellationModalOpen] = useState(false);

    const { booking, loading, isCanceling } = useSelector(selectAppointmentDetails);
    const { getBooking, cancelAppointment } = useActions(appointmentDetailsActions);

    const navigate = useLangNavigate();

    useEffect(() => {
        if (searchParams.get('isCancelling') === 'true') {
            setCancellationModalOpen(true);
        }
    }, [searchParams]);

    useEffect(() => {
        if (!bookingUuid) return;
        getBooking(bookingUuid);
    }, [bookingUuid, getBooking]);

    const onCancelClick = useCallback(() => {
        setCancellationModalOpen(true);
    }, []);

    const onOkClick = useCallback(() => {
        cancelAppointment();
        setCancellationModalOpen(false);
    }, [cancelAppointment]);

    const closeCancellationModal = useCallback(() => setCancellationModalOpen(false), []);

    const onMakeAppointmentClick = useCallback(() => {
        navigate(ROUTE_CHOOSE_STORE);
    }, [navigate]);

    if (loading) return <div>Loading...</div>;
    if (!booking) return <div>No such schedule</div>;

    return (
        <Wrapper>
            <ConfirmModal
                title={t('cancel_reservation')}
                content={t('abandon_reservation')}
                okText={t('yes')}
                cancelText={t('no')}
                onOk={onOkClick}
                onCancel={closeCancellationModal}
                open={cancellationModalOpen}
            />
            {booking.status === BookingStatus.BOOKED && (
                <>
                    <TitleWrapper>
                        <IconSuccess name="success-filled" verticalAlign="text-top" />
                        <StyledTitle>
                            {t('appointment_is')}
                            <SuccessText>{t('confirmed')}</SuccessText>
                        </StyledTitle>
                    </TitleWrapper>
                    <StyledSubtitle>{t('check_details')}</StyledSubtitle>
                </>
            )}
            {booking.status === BookingStatus.CANCELLED && (
                <>
                    <TitleWrapper>
                        <IconError name="error-filled" verticalAlign="text-top" />
                        <StyledTitle>
                            {t('your_reservation_has_been')}
                            <ErrorText>{t('canceled')}</ErrorText>
                        </StyledTitle>
                    </TitleWrapper>
                    <StyledSubtitle>{t('please_make_another_appointment')}</StyledSubtitle>
                </>
            )}
            <Divider />
            <AppointmentInfo start_at={dayjs.tz(new Date(booking.start_at))} duration={booking.duration} />
            <EntityWrapper>
                <EntityInfo />
                <EntityHours />
            </EntityWrapper>
            <Divider />
            <CustomerInfo
                firstname={booking.customer.firstname}
                lastname={booking.customer.lastname}
                phone={booking.customer.phone}
                email={booking.customer.email}
            />
            <CustomerComment comment={booking?.customer.comment} />
            <Divider />
            {booking.status === BookingStatus.BOOKED && (
                <StyledSubtitle className="text-action">{t('text_action')}</StyledSubtitle>
            )}
            <AppointmentActions>
                {booking.status === BookingStatus.BOOKED && (
                    <AppointmentButton
                        type="primary"
                        danger
                        icon={<Icon name="close" size="sm" verticalAlign="text-top" />}
                        onClick={onCancelClick}
                        loading={isCanceling}
                    >
                        {t('cancel')}
                    </AppointmentButton>
                )}

                {booking.status === BookingStatus.CANCELLED && (
                    <CancelButton
                        type="primary"
                        icon={<Icon name="calendar" size="sm" verticalAlign="text-top" />}
                        onClick={onMakeAppointmentClick}
                    >
                        {t('make_appointment')}
                    </CancelButton>
                )}
            </AppointmentActions>
        </Wrapper>
    );
};
