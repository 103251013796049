import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { ClientResponse } from 'sdkore';

import type { Resource } from 'models';
import { selectSelectedEntityUuid } from 'redux/selectors';
import { getAppointment, getAppointmentFailure, getAppointmentSuccess } from 'redux/slices';
import { assignmentsAccessor, getDuration, getInterval, showNoServiceError } from 'utils';

export function* getAppointmentSaga() {
    try {
        const entityUuid: string | undefined = yield select(selectSelectedEntityUuid);

        if (!entityUuid) {
            console.error('No entity uuid');
            return;
        }

        const { data }: ClientResponse<Resource[]> = yield call(() =>
            assignmentsAccessor.getActiveAppointments(entityUuid),
        );
        const [appointment] = data;
        if (!appointment) {
            console.error('No appointment created');
            return;
        }
        const duration: number | undefined = yield call(getDuration, appointment.resource_param_values);
        const interval: number | undefined = yield call(getInterval, appointment.resource_param_values);

        if (!interval) {
            console.error('No interval');
            return;
        }
        if (!duration) {
            console.error('No duration');
            return;
        }

        const mappedAppointment = {
            label: appointment.label,
            uuid: appointment.uuid,
            duration,
            interval,
        };

        yield put(getAppointmentSuccess(mappedAppointment));
        return mappedAppointment;
    } catch (error: any) {
        yield put(getAppointmentFailure());
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(getAppointment.type, getAppointmentSaga);
}
