import React, { useCallback } from 'react';
import { Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';
import { entitiesActions, entityActions } from 'redux/slices';
import { selectEntityLoading, selectOpeningHoursLoading } from 'redux/selectors';

interface MarkerProps {
    position: {
        lat: number;
        lng: number;
    };
    id: number;
    isSelected: boolean;
    clusterer: Clusterer;
}

export const CustomMarker: React.FC<MarkerProps> = ({ id, position, isSelected, clusterer }) => {
    const entityLoading = useSelector(selectEntityLoading);
    const openingHoursLoading = useSelector(selectOpeningHoursLoading);
    const loading = entityLoading || openingHoursLoading;
    const cursor = loading ? 'wait' : 'pointer';

    const { getEntity } = useActions(entityActions);
    const { setEntityIsChosen } = useActions(entitiesActions);

    const selectMarker = useCallback(() => {
        if (loading) return;
        setEntityIsChosen(id);
        getEntity(id);
    }, [loading, setEntityIsChosen, id, getEntity]);

    return isSelected ? (
        <Marker clusterer={clusterer} position={position} icon="/selected-marker.svg" zIndex={1000} />
    ) : (
        <Marker
            clusterer={clusterer}
            position={position}
            icon="/marker.svg"
            zIndex={1}
            onClick={selectMarker}
            cursor={cursor}
        />
    );
};
