import React, { FC } from 'react';

import { StyledConfirmModal } from './ConfirmModal.styled';

interface ConfirmModalProps {
    title: string;
    content: string;
    okText: string;
    cancelText: string;
    onOk: () => void;
    onCancel: () => void;
    open: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ title, content, okText, cancelText, onOk, onCancel, open }) => {
    return (
        <StyledConfirmModal
            title={title}
            open={open}
            closable={false}
            okText={okText}
            cancelText={cancelText}
            onOk={onOk}
            onCancel={onCancel}
            style={{ top: 270 }}
        >
            {content}
        </StyledConfirmModal>
    );
};
