import React, { FC, ReactNode } from 'react';
import Button from 'antd/lib/button';
import Title from 'antd/lib/typography/Title';

import { StyledModal } from './InfoModal.styled';

interface InfoModalProps {
    visible: boolean;
    title: string;
    content: ReactNode;
    okText: string;
    onOk: () => void;
}

export const InfoModal: FC<InfoModalProps> = ({ content, visible, okText, onOk, title }) => {
    return (
        <StyledModal
            open={visible}
            closable={false}
            title={<Title>{title}</Title>}
            style={{ top: 250 }}
            footer={
                <Button onClick={onOk} type="primary" size="large" block>
                    {okText}
                </Button>
            }
        >
            {content}
        </StyledModal>
    );
};
