import { call, put, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';

import { extractWorkWeek, resourcesAvailabilitiesAccessor, showNoServiceError } from 'utils';
import { getOpeningHours, getOpeningHoursFailure, getOpeningHoursSuccess } from 'redux/slices/sliceOpeningHours';
import type { ResourcesAvailability, StringInterval } from 'models';

function* getOpeningHoursSaga({ payload }: ReturnType<typeof getOpeningHours>) {
    try {
        const { shopUuid } = payload;

        const data: ResourcesAvailability[] = yield call(() =>
            resourcesAvailabilitiesAccessor.findAllByCriteria({
                resource: [shopUuid],
                start_at_min: dayjs.tz(new Date()).startOf('week').format(),
                end_at_max: dayjs.tz(new Date()).endOf('week').format(),
            }),
        );

        const workWeek: Record<string, StringInterval[]> = yield call(extractWorkWeek, data);
        yield put(getOpeningHoursSuccess(workWeek));
    } catch (error) {
        yield put(getOpeningHoursFailure());
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(getOpeningHours.type, getOpeningHoursSaga);
}
