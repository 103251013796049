import React from 'react';
import styled from 'styled-components';
import Text, { TextProps } from 'antd/lib/typography/Text';

export const HeaderStyled = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100px;

    background: ${({ theme }) => theme.neutral1};
    border-bottom: 1px solid ${({ theme }) => theme.neutral9};

    svg {
        margin: 4px 45px 26px 54px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;
        height: 87px;
        justify-content: center;
        align-items: center;

        svg {
            width: 127px;
            height: 39px;
            margin: 0;
        }
    }
`;

export const TextStyled = styled<React.FC<TextProps>>(Text)`
    font-size: ${({ theme }) => `${theme.fontSizeMd}px`};
    line-height: 100px;
    letter-spacing: 0.5px;
    font-weight: 300;
    text-transform: uppercase;
    white-space: nowrap;
    color: ${({ theme }) => theme.neutral8};

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        font-size: ${({ theme }) => `${theme.fontSizeXxs}px`};
        line-height: 24px;
    }
`;

export const LogoStyled = styled.div`
    cursor: pointer;
`;
