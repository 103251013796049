import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';

import { Wrapper } from './TimeGroup.styled';

interface TimeGroupProps {
    name: string;
    children: ReactNode;
}

export const TimeGroup: FC<TimeGroupProps> = ({ name, children }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Title level={5}>{t(name)}</Title>
            <div>{children}</div>
        </Wrapper>
    );
};
