import type { RootState } from 'models';

export const defaultState: RootState = {
    initial: {
        loading: false,
        initialized: false,
    },
    entities: {
        loading: false,
        list: [],
        hoveredEntity: undefined,
    },
    entity: {
        loading: false,
        loadingClosestEntity: false,
        id: undefined,
        selectedEntity: undefined,
        coordinates: undefined,
        closestEntities: [],
        next: false,
        results: undefined,
    },
    openingHours: {
        loading: false,
        data: undefined,
    },
    appointment: {
        loading: false,
        data: undefined,
    },
    calendar: {
        loading: true,
        availabilities: undefined,
        date: undefined,
        visibleDate: undefined,
        slots: {},
        pickedSlot: undefined,
    },
    verification: {
        loading: false,
        resource: undefined,
        blockTag: undefined,
        customerUuid: undefined,
        resourceLoading: false,
        isCleaningUp: false,
    },
    contactInfo: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        comment: undefined,
    },
    appointmentDetails: {
        loading: false,
        booking: undefined,
        isCanceling: false,
    },
};
