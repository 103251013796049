import styled from 'styled-components';
import Button from 'antd/lib/button';
import Title from 'antd/lib/typography/Title';
import { Icon } from '@wynd-bo/keystone-icons';

export const IconStyled = styled(Icon)`
    position: absolute;
    color: ${({ theme }) => theme.valid6};
    width: 48px !important;
    height: 48px !important;
    padding-right: 10px;
    margin: -7px 0px 0px -5px;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 20px !important;
        height: 20px !important;
        margin: 0 0 0 0;
    }
`;

export const Wrapper = styled.div`
    li::marker {
        color: ${({ theme }) => theme.primary6};
    }

    ul {
        margin: 7px 0 28px 0;
        padding: 0 0 0 30px;
    }
    li {
        margin: 0 0 6px 0;

        span {
            display: block;
            margin-left: 5px;
            line-height: 24px;
        }
    }
    h3 {
        margin-bottom: 0;
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        h3 {
            margin-top: 17px !important;
        }
        ul {
            margin: 6px 0 20px 0;
            padding: 0 0 0 33px;
        }
        li {
            span {
            }
        }
    }
`;

export const SpanStyled = styled.span`
    color: ${({ theme }) => theme.valid6};
`;

export const MailStyled = styled.span`
    text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
    display: block;
    margin-top: 23px;
    padding: 0 7px !important;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        display: block;
        width: 100%;
        margin-top: 30px;
    }
`;

export const TitleStyled = styled(Title)`
    margin: 30px 0 23px 53px !important;
    line-height: 40px !important;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin: 17px 0 16px 35px !important;
        line-height: 32px !important;
    }
`;
