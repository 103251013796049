import styled from 'styled-components';

export const CalendarWrapper = styled.div`
    max-width: 438px;
    height: 386px;
    padding-right: 40px;
    border-right: 1px solid ${({ theme }) => theme.neutral3};
    margin-right: 40px;
    margin-top: 10px;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        max-width: none;
        padding-right: 0;
        border: none;
        margin-right: 0;
        height: inherit;
    }
`;

export const CalendarHeader = styled.div`
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    h3 {
        margin: 0;
        text-transform: capitalize;
        line-height: 48px;
    }
`;

export const DateCell = styled.span<{ isSelected: boolean }>`
    background: ${({ theme, isSelected }) => (isSelected ? theme.primary6 : theme.neutral1)};
    color: ${({ theme, isSelected }) => (isSelected ? theme.neutral1 : theme.neutral8)};
`;
