import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from 'antd/lib/button';

export const ButtonStyled = styled<React.FC<ButtonProps>>(Button)`
    margin-top: 20px;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 100%;
    }
`;

export const ButtonBlock = styled<React.FC<ButtonProps>>(Button)`
    display: block;

    svg {
        margin-top: 2px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-top: 10px;
    }
`;

export const CalendarWrapper = styled.div`
    display: flex;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;
    }
`;

export const TimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const EntityWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.screenXs}) {
        flex-direction: column;
    }
`;

export const NoSlots = styled.div`
    width: calc(100% - 50px);
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizeXxs}px;
    padding: 24px;
    color: ${({ theme }) => theme.neutral7};
    background-color: ${({ theme }) => theme.neutral2};
    border-radius: ${({ theme }) => theme.borderRadius}px;
    margin-top: 52px;

    @media (max-width: ${({ theme }) => theme.screenXs}) {
        margin-top: 0;
    }
`;
