import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

export const StyledNavigation = styled.div`
    display: flex;
    max-width: 626px;
    margin-top: 32px;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 100%;
        max-width: none;
        flex-direction: column-reverse;
        margin-top: 4px;
    }
`;

export const AppointmentDateTime = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;

    h3 {
        margin: 0 !important;
        color: ${({ theme }) => theme.primary6};
        line-height: 24px;
    }

    h4 {
        margin-top: 32px;
        margin-bottom: 0;
        line-height: 32px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const AppointmentDuration = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;

    h4 {
        margin: 0;
        line-height: 32px;
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-top: 8px;
        margin-bottom: 8px;
    }
`;

export const Separator = styled.span`
    display: block;
    width: 100%;
    max-width: 626px;
    height: 1px;
    background-color: ${({ theme }) => theme.neutral4};

    &:nth-of-type(2) {
        margin: 18px 0 18px 0;
    }
    &:nth-of-type(3) {
        margin: -1px 0 19px 0;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        &:nth-of-type(1) {
            margin: 0 0 14px 0;
        }
        &:nth-of-type(2) {
            margin: 11px 0 -7px 0;
        }
        &:nth-of-type(3) {
            margin: -9px 0 9px 0;
        }
    }
`;

export const ModalText = styled(Text)`
    font-size: 19px;
    line-height: 25px;
`;
