import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import Skeleton from 'antd/lib/skeleton';

export const StyledSkeleton = styled(Skeleton)`
    width: 305px;
    margin-right: 40px;
`;

export const TitleStyled = styled(Title)`
    color: ${({ theme }) => theme.primary6} !important;
    margin-bottom: 4px !important;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-top: 0;
    }
`;

export const StyledSpace = styled.div`
    display: flex;
    flex-direction: column;
    width: 305px;
    margin-right: 40px;

    .ant-space-item {
        height: fit-content;
        margin-bottom: -7px;
    }
`;

export const PhoneWrapper = styled(Text)`
    display: flex;
    margin: 7px 0 0 0;
    svg {
        padding-top: 4px;
        margin-right: 8px;
    }
`;

export const TextStyled = styled(Text)`
    line-height: 24px;
`;
