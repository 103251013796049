import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CommentRoot } from './CustomerComment.styled';

interface CustomerCommentProps {
    comment: null | undefined | string;
}

export const CustomerComment: FC<CustomerCommentProps> = ({ comment }) => {
    const { t } = useTranslation();

    if (!comment) return null;

    return (
        <CommentRoot data-testid="comment-root" className="comment">
            <Title level={4}>{t('your_comment')}</Title>
            <Paragraph>{comment}</Paragraph>
        </CommentRoot>
    );
};
