import React, { FC } from 'react';
import Space from 'antd/lib/space';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectEntityLoading, selectOpeningInfo } from 'redux/selectors';

import { DayHours } from '../DayHours';
import { StyledSkeleton, TitleStyled } from './EntityHours.styled';

export const EntityHours: FC = () => {
    const { t } = useTranslation();
    const { data: openingHours, loading } = useSelector(selectOpeningInfo);
    const entityLoading = useSelector(selectEntityLoading);

    const arrayHours = Object.entries(openingHours || {});

    return (
        <StyledSkeleton loading={loading || entityLoading} paragraph={{ rows: 5 }} active>
            <Space direction="vertical" className="entity-hours">
                {!!arrayHours.length && <TitleStyled level={4}>{t('opening_hours')}</TitleStyled>}
                {arrayHours.map(([day, hours]) => (
                    <DayHours key={day} day={day} hours={hours} />
                ))}
            </Space>
        </StyledSkeleton>
    );
};
