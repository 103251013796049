import styled from 'styled-components';
import Modal from 'antd/lib/modal';

export const StyledModal = styled(Modal)`
    max-width: 598px;
    text-align: center;

    h1 {
        font-size: ${({ theme }) => theme.fontSizeLg}px !important;
        color: ${({ theme }) => theme} !important;
    }
    .ant-modal-header {
        margin-bottom: 26px;
    }
    .ant-modal-content {
        padding: 32px 38px 49px;
    }
    .ant-modal-footer {
        margin-top: 49px !important;

        button {
            height: 57px !important;

            span {
                font-size: 19px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        bottom: 0 !important;
        top: auto !important;

        h1 {
            font-size: ${({ theme }) => theme.fontSizeSm}px !important;
        }

        .ant-modal-header {
            margin-bottom: -2px;
        }
        .ant-modal-content {
            padding: 16px 14px 19px;

            span {
                font-size: ${({ theme }) => theme.fontSizeXs}px;
                line-height: 24px;

                strong:before {
                    content: ' ';
                    display: block;
                }
            }
        }

        .ant-modal-footer {
            margin-top: 28px !important;

            button {
                padding: 12px 14px 20px;
                height: 48px !important;

                span {
                    font-size: ${({ theme }) => theme.fontSizeXs}px;
                }
            }
        }
    }
`;
