import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import Form from 'antd/lib/form';
import { Icon } from '@wynd-bo/keystone-icons';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-right: 585px;
    padding: 0 0 0 11px;
    gap: 12px;

    @media (max-width: ${({ theme }) => theme.screenLg}) {
        margin-right: 465px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column-reverse;
        margin-right: 0;
        padding: 0;
        gap: 23;

        button {
            width: 100%;
        }
    }
`;

export const TextStyled = styled(Text)`
    color: ${({ theme }) => theme.neutral5};
    font-size: ${({ theme }) => theme.fontSizeXxxs}px;
    margin-top: 2px;
`;

export const CommentTextItem = styled(Form.Item)`
    margin-bottom: 24px;

    span {
        line-height: 24px;
    }
`;

export const ItemStyled = styled(Form.Item)`
    margin-bottom: 1px;
`;

export const SuccessIcon = styled(Icon)`
    color: ${({ theme }) => theme.valid6};
`;

export const ErrorIcon = styled(Icon)`
    color: ${({ theme }) => theme.error6};
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;
        align-items: stretch;
    }
`;

export const FormWrapper = styled.div`
    margin-right: 167px;
    flex-grow: 1;

    @media (max-width: ${({ theme }) => theme.screenLg}) {
        margin-right: 0;
    }
`;

export const InfoWrapper = styled.div`
    padding: 40px 202px 32px 38px;
    background-color: ${({ theme }) => theme.neutral2};

    button {
        display: block;
    }

    @media (max-width: ${({ theme }) => theme.screenLg}) {
        padding: 40px;
        margin-left: 40px;
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        padding: 15px;
        margin-bottom: 6px;
        margin-left: 0;
    }
`;

export const GdprWrapper = styled(Form.Item)`
    &.ant-form-item-has-error .ant-form-item-control-input {
        padding: 12px 0 12px 16px;
        background-color: ${({ theme }) => theme.error2};
        border-radius: 4px;
    }

    .ant-form-item-control-input-content {
        display: flex;
    }
    a {
        text-transform: initial !important;
        text-decoration: underline;
        transition: ease all 0.2s;
        :hover {
            opacity: 0.7;
            text-decoration: underline;
        }
    }
    span {
        line-height: 24px;
    }
    .ant-checkbox-inner {
        border-radius: 3px;
        ::after {
            transform: rotate(45deg) scale(1) translate(-3px, -4px);
        }
    }
    .ant-typography {
        display: block;
    }
    .ant-checkbox-wrapper {
        margin-right: 9px;
    }
`;

export const FormStyled = styled(Form)`
    & {
        margin: 30px 0 0 0;

        .ant-form-item-explain-error {
            font-size: ${({ theme }) => theme.fontSizeXxxs}px;
        }
        .ant-form-item-has-error .ant-form-item-control-input {
            margin-bottom: 2px;
        }
    }
`;

export const InputStyled = styled(Input)`
    & {
        padding: 13px 15px 12px;
        font-size: ${({ theme }) => theme.fontSizeXxs}px;
    }
`;

export const LastNameInput = styled(InputStyled)`
    text-transform: uppercase;

    ::placeholder {
        text-transform: none;
    }
`;

export const TextAreaStyled = styled(Input.TextArea)`
    & {
        padding: 13px 15px 9px;
        font-size: ${({ theme }) => theme.fontSizeXxs}px;
    }
`;

export const ChangeStoreButton = styled(Button)`
    & {
        margin-top: 5px;

        span {
            margin: 0 5px;
        }
    }
`;

export const ReCaptchaError = styled(Text)`
    display: block;
    margin-bottom: 20px;
    color: #ff4d4f;
`;
