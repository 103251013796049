import { Schedule } from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { selectAppointmentDetails } from 'redux/selectors';
import { bookingsAccessor, mapBooking, schedulesAccessor, showNoServiceError } from 'utils';
import {
    getBooking,
    getBookingSuccess,
    getBookingFailure,
    cancelAppointment,
    cancelAppointmentFailure,
    cancelAppointmentSuccess,
    getEntity,
} from 'redux/slices';

function* getBookingSaga({ payload }: ReturnType<typeof getBooking>) {
    try {
        const booking: Schedule['booking'] = yield call(() => bookingsAccessor.readOne(payload));
        yield put(getEntity(booking.entity.id));
        yield put(getBookingSuccess(mapBooking(booking)));
    } catch (error: any) {
        yield put(getBookingFailure());
        yield call(showNoServiceError);
    }
}

function* cancelAppointmentSaga() {
    try {
        const { booking }: ReturnType<typeof selectAppointmentDetails> = yield select(selectAppointmentDetails);
        if (!booking) {
            console.error('No booking in store');
            return;
        }
        yield call(() => schedulesAccessor.cancelAppointment(booking.blockTag));
        if (booking.resource?.uuid) {
            yield call(() => schedulesAccessor.unaffectResource(booking.blockTag, booking.resource.uuid));
        }
        yield put(cancelAppointmentSuccess());
        yield put(getBooking(booking.uuid));
    } catch (error: any) {
        yield put(cancelAppointmentFailure());
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(getBooking.type, getBookingSaga);
    yield takeLatest(cancelAppointment.type, cancelAppointmentSaga);
}
