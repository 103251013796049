import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '../Header';
import { LogoHeader } from '../LogoHeader';
import { ContainerStyled } from './Layout.styled';

interface LayoutProps {
    showLogoOnly?: boolean;
}

export const Layout: FC<LayoutProps> = ({ showLogoOnly }) => (
    <ContainerStyled>
        {showLogoOnly ? <LogoHeader /> : <Header />}
        <Outlet />
    </ContainerStyled>
);
