import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';

import type { Appointment, RootState } from 'models';
import { defaultState } from '../store/initialStates';

type AppointmentState = RootState['appointment'];

interface AppointmentSlice extends SliceCaseReducers<AppointmentState> {
    getAppointment: CaseReducer<AppointmentState>;
    getAppointmentSuccess: CaseReducer<AppointmentState, PayloadAction<Appointment>>;
    getAppointmentFailure: CaseReducer<AppointmentState>;
    clearAppointment: CaseReducer<AppointmentState>;
}

export const sliceAppointment = createSlice<AppointmentState, AppointmentSlice>({
    initialState: defaultState.appointment,
    name: '@@appointment',
    reducers: {
        getAppointment: (state) => {
            state.loading = true;
        },
        getAppointmentSuccess: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        getAppointmentFailure: (state) => {
            state.loading = false;
        },
        clearAppointment: (state) => {
            state.loading = defaultState.appointment.loading;
            state.data = defaultState.appointment.data;
        },
    },
});

export const appointmentActions = sliceAppointment.actions;

export const { getAppointment, getAppointmentSuccess, getAppointmentFailure, clearAppointment } =
    sliceAppointment.actions;
