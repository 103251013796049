import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 10vh;

    .ant-btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 17px;
        margin-bottom: 23px;
        padding: 0 19px;
    }
    .ant-btn-link {
        padding: 0;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        align-items: stretch;
        padding-top: 3vh;
    }
`;

export const TextStyled = styled(Text)`
    margin-bottom: 44px;
    margin-top: -4px;
    font-weight: 600;
    font-size: 16px;
`;

export const CodeStyled = styled.div`
    color: ${({ theme }) => theme.neutral3};
    font-weight: 500;
    font-size: 124px;
    line-height: 112px;
`;
