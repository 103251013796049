import dayjs from 'dayjs';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import type { ClientResponse } from 'sdkore';

import type { DayjsInterval, StringInterval, Appointment } from 'models';
import {
    selectSelectedShopUuid,
    selectAvailabilities,
    selectAppointment,
    selectSelectedEntityId,
} from 'redux/selectors';
import {
    getAvailabilities,
    getAvailabilitiesFailure,
    getAvailabilitiesSuccess,
    setSlots,
    disableLoader,
} from 'redux/slices';
import {
    convertToDayJs,
    cutSlots,
    getIntervalsIntersections,
    getStartAndEnd,
    humansAccessor,
    mergeOverlappingIntervals,
    monthChanged,
    showNoServiceError,
    storesAccessor,
} from 'utils';
import { DAY_MONTH_YEAR } from 'const';

import { getAppointmentSaga } from './sagaAppointment';

export function* getAvailabilitiesSaga({ payload }: ReturnType<typeof getAvailabilities>) {
    try {
        const shopUuid: string = yield select(selectSelectedShopUuid);
        const entityId: number = yield select(selectSelectedEntityId);
        const avails: Record<string, StringInterval[]> = yield select(selectAvailabilities);
        const date = dayjs.tz(payload.visibleDate);

        if (!monthChanged(payload.visibleDate, avails)) {
            yield call(makeSlots, payload.date);
            yield put(disableLoader());
            return;
        }

        const appointment: Appointment = yield call(getAppointmentSaga);

        if (!appointment) {
            yield put(setSlots({}));
            yield put(getAvailabilitiesFailure());
            console.error('No appointment found');
            return;
        }

        const { start_at_from, start_at_to } = getStartAndEnd(date);

        const [{ data: working }, { data: opening }, { data: anonymous }]: [
            ClientResponse<StringInterval[]>,
            ClientResponse<StringInterval[]>,
            ClientResponse<StringInterval[]>,
        ] = yield all([
            call(() => storesAccessor.getStoreAvailability(shopUuid, start_at_from, start_at_to, 'working')),
            call(() => storesAccessor.getStoreAvailability(shopUuid, start_at_from, start_at_to, 'opening')),
            call(() =>
                humansAccessor.getAnonymousAvailabilities(start_at_from, start_at_to, entityId, appointment.uuid),
            ),
        ]);

        const workingOpeningIntersections: DayjsInterval[] = yield call(
            getIntervalsIntersections,
            mergeOverlappingIntervals(working.map(convertToDayJs)),
            mergeOverlappingIntervals(opening.map(convertToDayJs)),
        );
        const availabilities: DayjsInterval[] = yield call(
            getIntervalsIntersections,
            mergeOverlappingIntervals(anonymous.map(convertToDayJs)),
            workingOpeningIntersections,
        );

        const availabilitiesByDate = availabilities.reduce((acc: Record<string, StringInterval[]>, item) => {
            const date = item.start_at.format(DAY_MONTH_YEAR);
            const intervalInMinutes = (item.end_at.valueOf() - item.start_at.valueOf()) / 1000 / 60;

            if (intervalInMinutes < appointment.duration) {
                return acc;
            }
            const now = dayjs.tz(new Date());
            if (now.isSame(item.start_at, 'day') && now.isAfter(item.end_at)) {
                return acc;
            }

            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push({ start_at: item.start_at.format(), end_at: item.end_at.format() });
            return acc;
        }, {});

        if (!Object.values(availabilitiesByDate).length) {
            yield put(disableLoader());
        }

        yield put(getAvailabilitiesSuccess(availabilitiesByDate));
        yield call(makeSlots, payload.date);
    } catch (error: any) {
        yield put(getAvailabilitiesFailure());
        yield call(showNoServiceError);
    }
}

function* makeSlots(date: string | undefined) {
    try {
        if (!date) {
            yield put(setSlots({}));
            return;
        }
        const availabilities: Record<string, StringInterval[]> = yield select(selectAvailabilities);
        const dateKey = dayjs.tz(new Date(date)).format(DAY_MONTH_YEAR);
        const { duration, interval } = yield select(selectAppointment);

        const slots: Record<string, StringInterval[]> = yield call(
            cutSlots,
            availabilities[dateKey],
            interval,
            duration,
        );
        yield put(setSlots(slots));
    } catch (e) {
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(getAvailabilities.type, getAvailabilitiesSaga);
}
