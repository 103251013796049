import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';
import type { RootState, StringInterval } from 'models';

import { defaultState } from '../store/initialStates';

type OpeningHoursState = RootState['openingHours'];

interface OpeningHoursSlice extends SliceCaseReducers<OpeningHoursState> {
    getOpeningHours: CaseReducer<OpeningHoursState, PayloadAction<{ entityUuid: string; shopUuid: string }>>;
    getOpeningHoursSuccess: CaseReducer<OpeningHoursState, PayloadAction<Record<string, StringInterval[]>>>;
    getOpeningHoursFailure: CaseReducer<OpeningHoursState>;
    clearOpeningHours: CaseReducer<OpeningHoursState>;
}

export const sliceOpeningHours = createSlice<OpeningHoursState, OpeningHoursSlice>({
    initialState: defaultState.openingHours,
    name: '@@openingHours',
    reducers: {
        getOpeningHours: (state) => {
            state.loading = true;
        },
        getOpeningHoursSuccess: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        getOpeningHoursFailure: (state) => {
            state.loading = false;
        },
        clearOpeningHours: (state) => {
            state.loading = defaultState.openingHours.loading;
            state.data = defaultState.openingHours.data;
        },
    },
});

export const openingHoursActions = sliceOpeningHours.actions;

export const { getOpeningHours, getOpeningHoursSuccess, getOpeningHoursFailure } = sliceOpeningHours.actions;
