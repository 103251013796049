import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Icon } from '@wynd-bo/keystone-icons';
import Button from 'antd/lib/button/button';
import { useSelector } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';

import { EntityInfo, Stepper, InfoModal, CustomerInfo, CustomerComment } from 'components';
import {
    DAY_NAME,
    ROUTE_CHOOSE_STORE,
    ROUTE_CONFIRMATION,
    ROUTE_CONTACT_INFORMATION,
    ROUTE_DATE_AND_HOUR,
    STEPS,
} from 'const';
import { useActions, useLangNavigate, useWindowDimensions } from 'hooks';
import { verificationActions } from 'redux/slices';
import {
    selectContactInfo,
    selectDuration,
    selectStartTime,
    selectVerificationCleanedUp,
    selectVerificationLoading,
} from 'redux/selectors';
import { capitalize } from 'utils';

import {
    AppointmentDateTime,
    AppointmentDuration,
    Separator,
    ModalText,
    StyledNavigation,
} from './Verification.styled';

export const Verification = () => {
    const { isMobile } = useWindowDimensions();
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const [slotModalShown, setSlotModalShown] = useState(false);
    const start_at = useSelector(selectStartTime)!;
    const duration = useSelector(selectDuration);
    const { firstname, lastname, phone, email, comment } = useSelector(selectContactInfo);
    const loading = useSelector(selectVerificationLoading);
    const isCleaningUp = useSelector(selectVerificationCleanedUp);
    const { createAppointment, getAvailableResources } = useActions(verificationActions);

    useEffect(() => {
        if (!start_at) {
            navigate(ROUTE_CHOOSE_STORE);
        }
    }, [navigate, start_at]);

    useEffect(() => {
        getAvailableResources({ onError: () => setSlotModalShown(true) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSuccess = useCallback(() => {
        navigate(ROUTE_CONFIRMATION);
    }, [navigate]);

    const goBack = useCallback(() => {
        navigate(ROUTE_CONTACT_INFORMATION);
    }, [navigate]);

    const validate = useCallback(() => {
        createAppointment({ onSuccess, onError: () => setSlotModalShown(true) });
    }, [createAppointment, onSuccess]);

    const goToDateAndHour = useCallback(() => {
        navigate(ROUTE_DATE_AND_HOUR);
        setSlotModalShown(false);
    }, [navigate]);

    useBeforeunload(() => '');

    if (!start_at) return null;

    return (
        <>
            <InfoModal
                visible={slotModalShown}
                okText={isMobile ? t('choose_a_time_slot') : t('choose_another_time_slot')}
                onOk={goToDateAndHour}
                title={t('slot_unavailable')}
                content={
                    <ModalText>
                        <Trans
                            i18nKey="datetime_unavailable"
                            values={{
                                date: capitalize(start_at.format('D MMMM')),
                                day: capitalize(start_at.format(DAY_NAME)),
                                hour: start_at.format('H'),
                                min: start_at.format('mm'),
                            }}
                        />
                    </ModalText>
                }
            />
            <div>
                <Stepper step={STEPS.VERIFICATION} />
                <Title>{t('verify_confirm_appointment')}</Title>
                <AppointmentDateTime>
                    <Title level={4}>{t('your_appointment_planned_for')}</Title>
                    <Title level={3}>
                        {`${capitalize(start_at.format(DAY_NAME))} ${start_at.format('D')} ${capitalize(
                            start_at.format('MMMM'),
                        )} ${t('at')} ${start_at.format('H')} h ${start_at.format('mm')}`}
                    </Title>
                </AppointmentDateTime>
                <AppointmentDuration>
                    <Title level={4}>{t('expected_duration')}</Title>
                    <Text>{t('expected_duration_start') + duration} min</Text>
                </AppointmentDuration>
                <Separator />
                <EntityInfo />
                <Separator />
                <CustomerInfo firstname={firstname} lastname={lastname} phone={phone} email={email} />
                <Separator />
                <CustomerComment comment={comment} />
                <StyledNavigation>
                    <Button
                        type="link"
                        onClick={goBack}
                        icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
                        size="large"
                    >
                        {t('return')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={validate}
                        icon={<Icon size="sm" name="check" verticalAlign="text-bottom" />}
                        size="large"
                        loading={loading || isCleaningUp}
                    >
                        {t('validate_appointment')}
                    </Button>
                </StyledNavigation>
            </div>
        </>
    );
};
