import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTE_HOME } from 'const';
import { ReactComponent as LogoSofa } from 'assets/logo.svg';
import { ModalStyled } from 'style/commonStyle';

import { HeaderStyled, TextStyled, LogoStyled } from './Header.styled';

export const Header = () => {
    const { t } = useTranslation();
    const [isModal, setModal] = useState(false);

    const showModal = useCallback(() => {
        setModal(true);
    }, []);

    const hideModal = useCallback(() => {
        setModal(false);
    }, []);

    const redirectHome = useCallback(() => {
        window.location.replace(ROUTE_HOME);
    }, []);

    return (
        <HeaderStyled>
            <LogoStyled onClick={showModal}>
                <LogoSofa />
            </LogoStyled>
            <TextStyled>{t('book_an_appointment')}</TextStyled>
            <ModalStyled
                open={isModal}
                title={t('abandon')}
                okText={t('yes')}
                cancelText={t('no')}
                onOk={redirectHome}
                onCancel={hideModal}
            >
                {t('abandon_booking')}
            </ModalStyled>
        </HeaderStyled>
    );
};
