import type { ThemeConfig } from 'antd/lib/config-provider/context';

import type { wyndTheme } from 'const';

export const createAntdTheme = (theme: typeof wyndTheme): ThemeConfig => ({
    token: {
        fontSizeHeading1: theme.fontSizeLg,
        fontSizeHeading3: theme.fontSizeSm,
        fontSizeHeading4: theme.fontSizeXs,
        fontSizeHeading5: theme.fontSizeXxs,
        colorText: theme.neutral8,
        fontFamily: 'Inter',
        colorPrimary: theme.primary6,
        colorPrimaryHover: theme.primary5,
        colorTextTertiary: theme.neutral8,
        colorSplit: theme.neutral4,
        borderRadius: theme.borderRadius,
    },
    components: {
        Button: {
            colorBgContainerDisabled: theme.secondary2,
            colorTextDisabled: theme.neutral1,
            colorPrimary: theme.secondary6,
            colorText: theme.secondary6,
            colorPrimaryHover: theme.secondary5,
            colorLink: theme.primary8,
            colorLinkHover: theme.secondary5,
        },
        Input: {
            colorPrimaryHover: theme.secondary6,
            colorTextTertiary: theme.neutral6,
            colorTextQuaternary: theme.neutral6,
        },
        Steps: {
            colorSplit: theme.neutral8,
        },
        Checkbox: {
            size: 14,
            controlInteractiveSize: 14,
        },
    },
});
