import dayjs from 'dayjs';

import type { RootState } from 'models';

export const selectCalendar = ({ calendar }: RootState) => calendar;
export const selectAvailabilities = ({ calendar }: RootState) => calendar.availabilities;
export const selectCalendarLoading = ({ calendar }: RootState) => calendar.loading;
export const selectCalendarDate = ({ calendar }: RootState) => calendar.date;
export const selectSlots = ({ calendar }: RootState) => calendar.slots;
export const selectPickedSlot = ({ calendar }: RootState) => calendar.pickedSlot;
export const selectStartTime = ({ calendar }: RootState) => {
    if (!calendar.pickedSlot) {
        return;
    }
    return dayjs.tz(new Date(calendar.pickedSlot?.start_at));
};
