import { AbstractAccessor } from 'sdkore';

export class StoresAccessor extends AbstractAccessor {
    constructor() {
        super('/public/booking_management/stores');
    }

    public async getStoreAvailability(
        shopUuid: string,
        start_at_from: string,
        start_at_to: string,
        type: 'opening' | 'working',
    ) {
        const query = await this.buildQueryString({
            start_at_from,
            start_at_to,
            type,
        });

        return this.client.get(`${this.route}/${shopUuid}/availability?${query}`);
    }
}
