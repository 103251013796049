import Modal from 'antd/lib/modal';
import styled from 'styled-components';

export const StyledConfirmModal = styled(Modal)`
    text-align: center;
    width: 533px !important;

    .ant-modal-content {
        padding: 46px 50px 50px;
        max-width: 533px;
    }

    .ant-modal-body {
        margin: 16px 0 22px 0;
        font-size: ${({ theme }) => theme.fontSizeXs}px;
    }

    .ant-modal-title {
        font-size: ${({ theme }) => theme.fontSizeLg}px;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: space-between;

        button {
            height: 48px;
            margin: 0 !important;
            width: 207px !important;
        }

        .ant-btn-primary {
            order: 1;
        }
        .ant-btn-default {
            order: 2;
        }
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: auto !important;

        .ant-modal-content {
            padding: 24px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

        .ant-modal-title {
            font-size: ${({ theme }) => theme.fontSizeSm}px;
        }

        .ant-modal-body {
            margin: 10px 0 29px 0;
        }

        .ant-modal-footer {
            flex-direction: column;

            button {
                width: 100% !important;
            }

            .ant-btn-default {
                margin-top: 16px !important;
            }
        }
    }
`;
