import styled from 'styled-components';

export const HeaderStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100px;

    background: ${({ theme }) => theme.neutral1};
    box-shadow: ${({ theme }) => theme.boxShadow1};

    svg {
        margin: 8px 54px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        justify-content: flex-start;
        height: 87px;

        svg {
            margin: 20px 15px 8px;
            width: 127px;
            height: 40px;
        }
    }
`;
