import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AppointmentCustomer } from './CustomerInfo.styled';

interface CustomerInfoProps {
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
}

export const CustomerInfo: FC<CustomerInfoProps> = ({ firstname, lastname, phone, email }) => {
    const { t } = useTranslation();

    return (
        <AppointmentCustomer data-testid="customer-info-root" className="customer-info">
            <Title level={4}>{t('to_contact_you')}</Title>
            <Paragraph>{`${firstname} ${lastname}`}</Paragraph>
            {phone && (
                <Paragraph>
                    {t('mobile')} : {phone}
                </Paragraph>
            )}
            {email && (
                <Paragraph>
                    {t('email')} : {email}
                </Paragraph>
            )}
        </AppointmentCustomer>
    );
};
