import { AbstractAccessor } from 'sdkore';

export class UsersAccessor extends AbstractAccessor {
    constructor() {
        super('/public/users');
    }

    public async getCurrentUser() {
        return (await this.client.get(`${this.route}/current`)).data;
    }
}
