import React, { FC } from 'react';

import { ReactComponent as LogoSofa } from 'assets/logo.svg';

import { HeaderStyled } from './LogoHeader.styled';

export const LogoHeader: FC = () => {
    return (
        <HeaderStyled>
            <LogoSofa />
        </HeaderStyled>
    );
};
