import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';
import type { Entity, RootState } from 'models';

import { defaultState } from '../store/initialStates';

type EntityState = RootState['entity'];

interface EntitySlice extends SliceCaseReducers<EntityState> {
    getEntity: CaseReducer<EntityState, PayloadAction<number>>;
    getEntitySuccess: CaseReducer<EntityState, PayloadAction<Entity>>;
    setEntityId: CaseReducer<EntityState, PayloadAction<number>>;
    getEntityFailure: CaseReducer<EntityState>;
    getSelectedShopUuid: CaseReducer<EntityState, PayloadAction<string>>;
    getSelectedShopUuidSuccess: CaseReducer<EntityState, PayloadAction<string>>;
    getSelectedShopUuidFailure: CaseReducer<EntityState>;
    getClosestEntity: CaseReducer<EntityState, PayloadAction<google.maps.LatLngLiteral>>;
    getMoreClosestEntity: CaseReducer<EntityState, PayloadAction<google.maps.LatLngLiteral | undefined>>;
    getClosestEntitySuccess: CaseReducer<EntityState, PayloadAction<Entity[]>>;
    setNext: CaseReducer<EntityState, PayloadAction<boolean>>;
    getClosestEntityFailure: CaseReducer<EntityState>;
    resetEntity: CaseReducer<EntityState>;
    resetResults: CaseReducer<EntityState>;
    getEntityByStoreId: CaseReducer<EntityState, PayloadAction<{ id: string; navigate: (route: string) => void }>>;
    enableLoadingClosestEntity: CaseReducer<EntityState>;
    disableLoadingClosestEntity: CaseReducer<EntityState>;
    resetSelectedEntity: CaseReducer<EntityState>;
}

export const sliceEntity = createSlice<EntityState, EntitySlice>({
    initialState: defaultState.entity,
    name: '@@entity',
    reducers: {
        getEntity: (state) => {
            state.loading = true;
        },
        getEntitySuccess: (state, { payload }) => {
            state.loading = false;
            state.selectedEntity = payload;
        },
        setEntityId: (state, { payload }) => {
            state.id = payload;
        },
        getEntityFailure: (state) => {
            state.loading = false;
        },
        getSelectedShopUuid: (state) => {
            state.loading = true;
        },
        getSelectedShopUuidSuccess: (state, { payload }) => {
            state.loading = false;
            state.selectedShopUuid = payload;
        },
        getSelectedShopUuidFailure: (state) => {
            state.loading = false;
        },
        enableLoadingClosestEntity: (state) => {
            state.loadingClosestEntity = true;
        },
        disableLoadingClosestEntity: (state) => {
            state.loadingClosestEntity = true;
        },
        getClosestEntity: (state, { payload }) => {
            state.loadingClosestEntity = true;
            !!payload && (state.coordinates = payload);
        },
        getClosestEntitySuccess: (state, { payload }) => {
            state.loadingClosestEntity = false;
            state.closestEntities = payload;
            state.results = payload.length;
        },
        setNext: (state, { payload }) => {
            state.next = payload;
        },
        getClosestEntityFailure: (state) => {
            state.loadingClosestEntity = false;
        },
        resetSelectedEntity: (state) => {
            state.selectedEntity = undefined;
        },
        resetEntity: () => defaultState.entity,
        resetResults: (state) => {
            state.results = undefined;
        },
        getMoreClosestEntity: (state) => {
            state.loadingClosestEntity = true;
        },
        getEntityByStoreId: (state) => {
            state.loading = true;
        },
    },
});

export const entityActions = sliceEntity.actions;

export const {
    setEntityId,
    getEntity,
    getEntitySuccess,
    getEntityFailure,
    getSelectedShopUuid,
    getSelectedShopUuidSuccess,
    getSelectedShopUuidFailure,
    getClosestEntity,
    getMoreClosestEntity,
    getClosestEntitySuccess,
    getClosestEntityFailure,
    resetEntity,
    resetResults,
    setNext,
    setClosestEntityIsChosen,
    getEntityByStoreId,
    enableLoadingClosestEntity,
    disableLoadingClosestEntity,
    resetSelectedEntity,
} = sliceEntity.actions;
