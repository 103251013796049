import React from 'react';
import Modal from 'antd/lib/modal';

import i18n from 'utils/i18next';
import { ReactComponent as NoService } from 'assets/no-service.svg';

export const showNoServiceError = () => {
    Modal.info({
        content: (
            <>
                <NoService />
                <div>{i18n.t('something_went_wrong')}</div>
            </>
        ),
        okText: i18n.t('retry'),
    });
};
