import { sliceInitial } from './sliceInitial';
import { sliceEntity } from './sliceEntity';
import { sliceEntities } from './sliceEntities';
import { sliceOpeningHours } from './sliceOpeningHours';
import { sliceAppointment } from './sliceAppointment';
import { sliceCalendar } from './sliceCalendar';
import { sliceContactInfo } from './sliceContactInfo';
import { sliceVerification } from './sliceVerification';
import { sliceAppointmentDetails } from './sliceAppointmentDetails';

export * from './sliceInitial';
export * from './sliceAppointment';
export * from './sliceEntity';
export * from './sliceEntities';
export * from './sliceCalendar';
export * from './sliceOpeningHours';
export * from './sliceContactInfo';
export * from './sliceVerification';
export * from './sliceAppointmentDetails';

export const reducers = {
    initial: sliceInitial.reducer,
    entity: sliceEntity.reducer,
    entities: sliceEntities.reducer,
    openingHours: sliceOpeningHours.reducer,
    appointment: sliceAppointment.reducer,
    calendar: sliceCalendar.reducer,
    contactInfo: sliceContactInfo.reducer,
    verification: sliceVerification.reducer,
    appointmentDetails: sliceAppointmentDetails.reducer,
};
