import {
    ResourcesAccessor,
    StoresAccessor,
    HumansAccessor,
    ResourcesAvailabilitiesAccessor,
    SearchAccessor,
    AssignmentsAccessor,
    SchedulesAccessor,
    UsersAccessor,
    CustomersAccessor,
    BookingsAccessor,
    ReCaptachaAccessor,
    EntityAccessor,
    AnonymousClient,
} from 'accessors';
import type { InitAccessors } from 'models';

let entityAccessor: EntityAccessor;
let resourcesAccessor: ResourcesAccessor;
let storesAccessor: StoresAccessor;
let humansAccessor: HumansAccessor;
let resourcesAvailabilitiesAccessor: ResourcesAvailabilitiesAccessor;
let searchAccessor: SearchAccessor;
let assignmentsAccessor: AssignmentsAccessor;
let schedulesAccessor: SchedulesAccessor;
let usersAccessor: UsersAccessor;
let customersAccessor: CustomersAccessor;
let bookingsAccessor: BookingsAccessor;
let reCaptachaAccessor: ReCaptachaAccessor;

export const initAccessors: InitAccessors = ({ apiUrl }) => {
    const client = new AnonymousClient({
        url: apiUrl,
        cachePolicy: false,
    });

    const clientIdentifier = client.register();

    client.addHeaders({
        'Content-Type': 'application/json',
    });

    entityAccessor = new EntityAccessor();
    resourcesAccessor = new ResourcesAccessor();
    storesAccessor = new StoresAccessor();
    humansAccessor = new HumansAccessor();
    resourcesAvailabilitiesAccessor = new ResourcesAvailabilitiesAccessor();
    searchAccessor = new SearchAccessor();
    assignmentsAccessor = new AssignmentsAccessor();
    schedulesAccessor = new SchedulesAccessor();
    usersAccessor = new UsersAccessor();
    customersAccessor = new CustomersAccessor();
    bookingsAccessor = new BookingsAccessor();
    reCaptachaAccessor = new ReCaptachaAccessor();

    entityAccessor.setClientId(clientIdentifier);
    resourcesAccessor.setClientId(clientIdentifier);
    storesAccessor.setClientId(clientIdentifier);
    humansAccessor.setClientId(clientIdentifier);
    resourcesAvailabilitiesAccessor.setClientId(clientIdentifier);
    searchAccessor.setClientId(clientIdentifier);
    assignmentsAccessor.setClientId(clientIdentifier);
    schedulesAccessor.setClientId(clientIdentifier);
    usersAccessor.setClientId(clientIdentifier);
    customersAccessor.setClientId(clientIdentifier);
    bookingsAccessor.setClientId(clientIdentifier);
    reCaptachaAccessor.setClientId(clientIdentifier);
};

export {
    entityAccessor,
    resourcesAccessor,
    storesAccessor,
    humansAccessor,
    resourcesAvailabilitiesAccessor,
    searchAccessor,
    assignmentsAccessor,
    schedulesAccessor,
    usersAccessor,
    customersAccessor,
    bookingsAccessor,
    reCaptachaAccessor,
};
