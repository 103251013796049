import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';

import type { BookingShort, RootState } from 'models';
import { defaultState } from '../store/initialStates';

type AppointmentDetailsState = RootState['appointmentDetails'];

interface AppointmentDetailsSlice extends SliceCaseReducers<AppointmentDetailsState> {
    getBooking: CaseReducer<AppointmentDetailsState, PayloadAction<string>>;
    getBookingSuccess: CaseReducer<AppointmentDetailsState, PayloadAction<BookingShort>>;
    getBookingFailure: CaseReducer<AppointmentDetailsState>;
    cancelAppointment: CaseReducer<AppointmentDetailsState>;
    cancelAppointmentSuccess: CaseReducer<AppointmentDetailsState>;
    cancelAppointmentFailure: CaseReducer<AppointmentDetailsState>;
}

export const sliceAppointmentDetails = createSlice<AppointmentDetailsState, AppointmentDetailsSlice>({
    initialState: defaultState.appointmentDetails,
    name: '@@appointmentDetails',
    reducers: {
        getBooking: (state) => {
            state.loading = true;
        },
        getBookingSuccess: (state, { payload }) => {
            state.loading = false;
            state.booking = payload;
        },
        getBookingFailure: (state) => {
            state.loading = false;
        },
        cancelAppointment: (state) => {
            state.isCanceling = true;
        },
        cancelAppointmentSuccess: (state) => {
            state.isCanceling = false;
        },
        cancelAppointmentFailure: (state) => {
            state.isCanceling = false;
        },
    },
});

export const appointmentDetailsActions = sliceAppointmentDetails.actions;

export const {
    getBooking,
    getBookingSuccess,
    getBookingFailure,
    cancelAppointment,
    cancelAppointmentSuccess,
    cancelAppointmentFailure,
} = sliceAppointmentDetails.actions;
