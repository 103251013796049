import dayjs from 'dayjs';
import pick from 'lodash.pick';

import type { BookingShort, Entity, EntityFull, Schedule } from 'models';

export const mapEntity = (entity: EntityFull): Entity =>
    pick(entity, [
        'uuid',
        'label',
        'address',
        'address_additional',
        'zipcode',
        'town',
        'phone',
        'id',
        'timezone',
        'distance',
        'latlong',
    ]);

export const mapEntities = (entities: EntityFull[]): Entity[] => entities.map(mapEntity);

export const mapCoordinates = (entities: Entity[]) =>
    entities.map((entity) => ({
        position: {
            lat: Number(entity?.latlong?.latitude),
            lng: Number(entity?.latlong?.longitude),
        },
        id: entity.id,
    }));

export const mapBooking = (booking: Schedule['booking']): BookingShort => {
    const duration = dayjs
        .tz(new Date(booking.booking_details[0].end_at))
        .diff(dayjs.tz(new Date(booking.booking_details[0].start_at)), 'minutes');

    if (booking.booking_details[0].timezone) {
        dayjs.tz.setDefault(booking.booking_details[0].timezone);
    }

    return {
        blockTag: booking.block_tag,
        start_at: booking.booking_details[0].start_at,
        end_at: booking.booking_details[0].end_at,
        resource: booking.booking_details?.[1]?.resource && {
            uuid: booking.booking_details[1].resource.uuid,
            name: booking.booking_details[1].resource.name,
            label: booking.booking_details[1].resource.label,
        },
        duration,
        customer: {
            lastname: booking.customer?.lastname || '',
            firstname: booking.customer?.firstname || '',
            email: booking.customer?.email || '',
            phone: booking.customer?.phone || '',
            comment: booking.comment || '',
        },
        entity: booking.entity,
        status: booking.status.code,
        uuid: booking.uuid,
    };
};
