import { call, put, takeLatest } from 'redux-saga/effects';

import { initAccessors } from 'utils';

import { getEntities, initAction, initActionFailure, initActionSuccess } from '../slices';

export function* init({ payload: { apiUrl } }: ReturnType<typeof initAction>) {
    try {
        yield call(() => initAccessors({ apiUrl }));
        yield put(getEntities());
        yield put(initActionSuccess());
    } catch (error: any) {
        yield put(initActionFailure());
    }
}

export default function* watcher() {
    yield takeLatest(initAction.type, init);
}
