import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StepsStyled } from './Stepper.styled';

interface StepperProps {
    step: number;
}

export const Stepper: React.FC<StepperProps> = ({ step }) => {
    const { t } = useTranslation();

    return (
        <StepsStyled
            size="small"
            responsive={false}
            current={step}
            items={[
                {
                    title: t('choose_store'),
                    icon: 1,
                },
                {
                    title: t('date_and_hour'),
                    icon: 2,
                },
                {
                    title: t('contact_information'),
                    icon: 3,
                },
                {
                    title: t('verification'),
                    icon: 4,
                },
                {
                    title: t('confirmation'),
                    icon: <CheckOutlined />,
                },
            ]}
        />
    );
};
