import styled from 'styled-components';

export const AppointmentCustomer = styled.div`
    margin: 16px 0;

    h4 {
        margin: 0;
        line-height: 32px;
    }
    & div {
        margin: 0;
        line-height: 24px;
    }
`;
