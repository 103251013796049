import styled from 'styled-components';

export const CommentRoot = styled.div`
    margin: 8px 0;
    h4 {
        margin: 0;
        line-height: 32px;
    }
    & div {
        margin: 0;
        max-width: 626px;

        @media (max-width: ${({ theme }) => theme.screenSm}) {
            line-height: 24px;
        }
    }
`;
