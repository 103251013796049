import { AbstractAccessor } from 'sdkore';

import { StatusTypes } from 'models';

export class SchedulesAccessor extends AbstractAccessor {
    constructor() {
        super('/public/booking_management/schedules');
    }

    public async makeBooking(blockTag: string, customerUuid: string) {
        return this.client.post(`${this.route}/${blockTag}/affect`, { customer: customerUuid, order: null });
    }

    public async assignResourceToSchedule(blockTag: string, resourceUuid: string) {
        return this.client.put(`${this.route}/${blockTag}/resources-affect`, [{ uuid: resourceUuid }]);
    }

    public async remove(blockTag: string) {
        return this.client.delete(`${this.route}/${blockTag}?duplicates=false`);
    }

    public async cancelAppointment(uuid: string) {
        return this.client.put(`${this.route}/${uuid}?duplicate=false`, {
            booking: { status: { uuid: StatusTypes.CANCELED }, state: 'disabled' },
        });
    }

    public async unaffectResource(blockTag: string, uuid: string) {
        return this.client.put(`${this.route}/${blockTag}/resources-unaffect`, [
            {
                uuid,
            },
        ]);
    }
}
