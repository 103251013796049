import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import updateLocale from 'dayjs/plugin/updateLocale';

const weekDaysCalendar = {
    en: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
    fr: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
};

export const initDayjs = async (language: string) => {
    await import(`dayjs/locale/${language}.js`);

    dayjs.extend(updateLocale);
    dayjs.locale(language);
    dayjs.updateLocale(language, {
        weekStart: 1,
        weekdaysMin: weekDaysCalendar[language as keyof typeof weekDaysCalendar],
    });
    dayjs.extend(minMax);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(customParseFormat);
    dayjs.extend(isoWeek);
    dayjs.tz.setDefault('Europe/Paris');
};
