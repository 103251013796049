import { reCaptachaAccessor } from 'utils';

// https://developers.google.com/recaptcha/docs/verify

declare global {
    interface Window {
        grecaptcha: ReCaptchaInstance;
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => void;
    execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
    action: string;
}

export const checkRecaptcha = (
    callback: () => any,
    callbackError: () => any,
    actionName: string,
    googleReCaptchaKey?: string,
) => {
    try {
        const grecaptcha = window.grecaptcha;

        if (googleReCaptchaKey && grecaptcha) {
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(googleReCaptchaKey, { action: actionName ?? 'empty' })
                    .then((token: string) => reCaptachaAccessor.verifyReCaptachaToken(token))
                    .then(callback)
                    .catch(callbackError);
            });
        }
    } catch (error) {
        return false;
    }
};
