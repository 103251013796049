import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationFr from 'locale/fr.json';
import translationEn from 'locale/en.json';

const i18next = i18n.createInstance();

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        defaultNS: 'common',
        resources: {
            'en-US': { common: translationEn },
            'fr-FR': { common: translationFr },
        },
        cleanCode: true,
        load: 'currentOnly',
        fallbackLng: 'fr-FR',
        supportedLngs: ['en-US', 'fr-FR'] as Array<'en-US' | 'fr-FR'>,
        returnNull: false,
        detection: {
            order: ['path'],
            caches: ['localStorage'],
        },
    });

export default i18next;
