import { EntityHours, EntityInfo } from 'components';
import React, { FC, useCallback } from 'react';
import { Icon } from '@wynd-bo/keystone-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ROUTE_DATE_AND_HOUR } from 'const';
import { useActions, useLangNavigate } from 'hooks';
import { getClosestEntity, selectSelectedEntityId } from 'redux/selectors';
import { entityActions } from 'redux/slices';

import { ButtonStyled, EntityWrapper, IconWrapper, StyledEntityDetailed } from './EntityDetailed.styled';

interface EntityDetailedProps {
    closeEntityDetailed: () => void;
}

export const EntityDetailed: FC<EntityDetailedProps> = ({ closeEntityDetailed }) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();

    const closestEntity = useSelector(getClosestEntity);
    const selectedEntityId = useSelector(selectSelectedEntityId);
    const { setEntityId } = useActions(entityActions);

    const onSelect = useCallback(() => {
        if (!selectedEntityId) return;
        setEntityId(selectedEntityId);
        navigate(ROUTE_DATE_AND_HOUR);
    }, [navigate, selectedEntityId, setEntityId]);

    return (
        <StyledEntityDetailed>
            <EntityWrapper onClick={onSelect}>
                <EntityInfo />
                <EntityHours />
                <IconWrapper>
                    <Icon size="sm" name="arrow-right" verticalAlign="text-bottom" />
                </IconWrapper>
            </EntityWrapper>
            <ButtonStyled
                type="link"
                onClick={closeEntityDetailed}
                disabled={false}
                icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
            >
                {!!closestEntity.length ? t('back_to_search_results') : t('back_to_all_stores')}
            </ButtonStyled>
        </StyledEntityDetailed>
    );
};
