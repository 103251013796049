import type { RootState } from 'models';
import { createSelector } from '@reduxjs/toolkit';

import { mapCoordinates } from 'utils';

export const selectEntities = ({ entities }: RootState) => entities;
export const selectEntitiesList = ({ entities }: RootState) => entities.list;
export const selectEntitiesLoading = ({ entities }: RootState) => entities.loading;
export const selectHoveredEntities = ({ entities }: RootState) => entities.hoveredEntity;

export const getAllEntitiesCoordinates = createSelector(selectEntitiesList, (entities) => mapCoordinates(entities));
