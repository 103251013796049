import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@wynd-bo/keystone-icons';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';

import { useLangNavigate } from 'hooks';
import { ROUTE_CHOOSE_STORE } from 'const';

import { Wrapper, CodeStyled, TextStyled } from './Error404.styled';

export const Error404 = () => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();

    const clickNext = useCallback(() => {
        navigate(ROUTE_CHOOSE_STORE);
    }, [navigate]);

    return (
        <Wrapper>
            <CodeStyled>404</CodeStyled>
            <TextStyled>{t('page_does_not_exist')}</TextStyled>
            <Text>{t('click_below')}</Text>
            <Button
                type="primary"
                icon={<Icon name="calendar" size="sm" verticalAlign="text-bottom" />}
                size="large"
                onClick={clickNext}
            >
                {t('make_appointment')}
            </Button>
            <Button type="link" size="large" onClick={clickNext}>
                {t('go_homepage_404')}
            </Button>
        </Wrapper>
    );
};
