import Button, { ButtonProps } from 'antd/lib/button';
import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const StyledButton = styled<FC<ButtonProps & { selected: boolean }>>(Button)`
    background-color: ${({ theme }) => theme.neutral1};
    border: 1px solid ${({ theme }) => theme.primary6};
    margin: 4px;
    font-size: 14px !important;
    color: ${({ theme }) => theme.neutral8};
    animation: ${appear} 0.2s linear 1;
    // set width here to delete blink when font-weight is changing after hover
    width: 138px;

    span {
        font-weight: 400;
        margin: 0;
    }

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.primary6};
        border: 1px solid ${({ theme }) => theme.primary6};
        color: ${({ theme }) => theme.neutral1};
        span {
            font-weight: 500;
        }
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: calc(50% - 8px);
    }
`;
