import { call, put, takeLatest, select } from 'redux-saga/effects';
import dayjs from 'dayjs';

import { entityAccessor, resourcesAccessor, searchAccessor, mapEntity, mapEntities, showNoServiceError } from 'utils';
import type { Resource, EntityFull } from 'models';
import { DEFAULT_COUNT, DEFAULT_TIMEZONE, ROUTE_CHOOSE_STORE } from 'const';

import {
    getEntity,
    getEntityFailure,
    getEntitySuccess,
    getClosestEntity,
    getClosestEntityFailure,
    getClosestEntitySuccess,
    getSelectedShopUuid,
    getSelectedShopUuidFailure,
    getSelectedShopUuidSuccess,
    setNext,
    getMoreClosestEntity,
    getEntityByStoreId,
    setEntityId,
    getOpeningHours,
} from '../slices';
import { selectEntityResults, selectEntityCoordinates } from '../selectors';

function* getEntitySaga({ payload }: ReturnType<typeof getEntity>) {
    try {
        const entity: EntityFull = yield call(() => entityAccessor.readOne(payload));
        yield put(getEntitySuccess(mapEntity(entity)));
        yield call(dayjs.tz.setDefault, entity.timezone || DEFAULT_TIMEZONE);
        yield put(getSelectedShopUuid(entity.uuid!));
    } catch (error) {
        yield put(getEntityFailure());
        yield call(showNoServiceError);
    }
}

function* getSelectedShopSaga({ payload }: ReturnType<typeof getSelectedShopUuid>) {
    try {
        const entityUuid = payload;
        const data: Resource[] = yield call(() => resourcesAccessor.getEntityStores(entityUuid));
        yield put(getSelectedShopUuidSuccess(data[0].uuid));
        yield put(getOpeningHours({ entityUuid, shopUuid: data[0].uuid }));
    } catch (error) {
        yield put(getSelectedShopUuidFailure());
        yield call(showNoServiceError);
    }
}

function* getClosestEntitySaga({ payload: { lat, lng } }: ReturnType<typeof getClosestEntity>) {
    try {
        const entities: EntityFull[] = yield call(() =>
            searchAccessor.getClosestEntity({
                latitude: lat,
                longitude: lng,
                limit: DEFAULT_COUNT + 1,
                distance: 500,
            }),
        );
        if (entities.length === DEFAULT_COUNT + 1) {
            entities.pop();
            yield put(setNext(true));
        } else {
            yield put(setNext(false));
        }
        yield put(getClosestEntitySuccess(mapEntities(entities)));
    } catch (error) {
        yield put(getClosestEntityFailure());
        yield call(showNoServiceError);
    }
}

function* getMoreClosestEntitySaga({ payload }: ReturnType<typeof getClosestEntity>) {
    try {
        const results: number = yield select(selectEntityResults);
        const coordinates: google.maps.LatLngLiteral = yield select(selectEntityCoordinates);
        // we ask elements + 1 to know there is next page or not
        const limit = results + DEFAULT_COUNT + 1;
        const entities: EntityFull[] = yield call(() =>
            searchAccessor.getClosestEntity({
                latitude: payload?.lat || coordinates.lat,
                longitude: payload?.lng || coordinates.lng,
                limit: limit,
                distance: 500,
            }),
        );
        // we drop last element if we got all elements + 1
        if (entities.length === limit) {
            entities.pop();
            yield put(getClosestEntitySuccess(mapEntities(entities)));
            yield put(setNext(true));
        } else {
            yield put(setNext(false));
        }
        yield put(getClosestEntitySuccess(mapEntities(entities)));
    } catch (error) {
        yield put(getClosestEntityFailure());
        yield call(showNoServiceError);
    }
}

function* getEntityByStoreIdSaga({ payload: { id, navigate } }: ReturnType<typeof getEntityByStoreId>) {
    try {
        const entities: EntityFull[] = yield call(() => entityAccessor.findAllByCriteria({ external_id: [id] }));
        if (!entities[0]) {
            yield put(getEntityFailure());
            yield call(navigate, ROUTE_CHOOSE_STORE);
        }
        yield put(getEntitySuccess(mapEntity(entities[0])));
        yield put(setEntityId(entities[0].id));
        yield call(dayjs.tz.setDefault, entities[0].timezone || DEFAULT_TIMEZONE);
        yield put(getSelectedShopUuid(entities[0].uuid!));
    } catch (error) {
        yield put(getEntityFailure());
    }
}

export default function* watcher() {
    yield takeLatest(getEntity.type, getEntitySaga);
    yield takeLatest(getEntityByStoreId.type, getEntityByStoreIdSaga);
    yield takeLatest(getClosestEntity.type, getClosestEntitySaga);
    yield takeLatest(getMoreClosestEntity.type, getMoreClosestEntitySaga);
    yield takeLatest(getSelectedShopUuid.type, getSelectedShopSaga);
}
