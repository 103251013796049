import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import ConfigProvider from 'antd/lib/config-provider';
import { ThemeProvider } from 'styled-components';
import enUS from 'antd/lib/locale/en_US';
import frFr from 'antd/lib/locale/fr_FR';

import { sofaTheme } from 'const';
import i18next from 'utils/i18next';
import { createAntdTheme } from 'utils';
import { store } from 'redux/store';
import { Routing } from './Routing';
import { GlobalStyles } from './style/commonStyle';

const LOCALES = {
    'en-US': enUS,
    'fr-FR': frFr,
};

export const ModuleBooking = () => {
    const antdTheme = useMemo(() => createAntdTheme(sofaTheme), []);

    return (
        <BrowserRouter>
            <ConfigProvider theme={antdTheme} locale={LOCALES[i18next.language as keyof typeof LOCALES]}>
                <ThemeProvider theme={sofaTheme}>
                    <Provider store={store}>
                        <I18nextProvider i18n={i18next}>
                            <GlobalStyles />
                            <Routing />
                        </I18nextProvider>
                    </Provider>
                </ThemeProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
};
