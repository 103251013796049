import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';

import type { DynamicConfig, RootState } from 'models';

import { defaultState } from '../store/initialStates';

type Initial = RootState['initial'];

interface InitSlice extends SliceCaseReducers<Initial> {
    initAction: CaseReducer<Initial, PayloadAction<DynamicConfig>>;
    initActionSuccess: CaseReducer<Initial>;
    initActionFailure: CaseReducer<Initial>;
}

export const sliceInitial = createSlice<Initial, InitSlice>({
    initialState: defaultState.initial,
    name: '@@initial',
    reducers: {
        initAction: (state) => {
            state.loading = true;
        },
        initActionSuccess: (state) => {
            state.loading = false;
            state.initialized = true;
        },
        initActionFailure: (state) => {
            state.loading = false;
        },
    },
});

export const initialActions = sliceInitial.actions;

export const { initAction, initActionSuccess, initActionFailure, initStoreData } = initialActions;
