import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import { useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { useSelector } from 'react-redux';
import { Icon } from '@wynd-bo/keystone-icons';
import Geocode from 'react-geocode';
import { useBeforeunload } from 'react-beforeunload';

import { Autocomplete, Map, Stepper } from 'components';
import { STEPS } from 'const';
import {
    getClosestEntity,
    selectClosestEntityLoading,
    selectEntityResults,
    selectSelectedEntity,
} from 'redux/selectors';
import { entitiesActions, entityActions, openingHoursActions } from 'redux/slices';
import { useActions } from 'hooks';

import { ButtonMobileStyled, ButtonStyled, InputsStyled } from './ChooseStore.styled';
import { EntityList } from '../EntityList';
import { EntityDetailed } from '../EntityDetailed';

const libraries: Libraries = ['places'];

export interface ChooseStoreProps {
    googleApiKey?: string;
}

export const ChooseStore: React.FC<ChooseStoreProps> = ({ googleApiKey }) => {
    const { t } = useTranslation();
    const [place, setPlace] = useState<string>();
    const [centerMap, setCenterMap] = useState<google.maps.LatLngLiteral>();
    const results = useSelector(selectEntityResults);
    const loadingClosestEntity = useSelector(selectClosestEntityLoading);
    const closestEntity = useSelector(getClosestEntity);
    const selectedEntity = useSelector(selectSelectedEntity);

    const { resetEntity, resetSelectedEntity } = useActions(entityActions);
    const { resetHoveredEntity } = useActions(entitiesActions);
    const { clearOpeningHours } = useActions(openingHoursActions);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleApiKey || '',
        libraries,
    });

    useEffect(() => {
        Geocode.setApiKey(googleApiKey || '');
        Geocode.setLanguage('fr');
        Geocode.setRegion('fr');
        Geocode.setLocationType('ROOFTOP');
    }, [googleApiKey]);

    const handleClickReturn = useCallback(() => {
        resetEntity();
        setCenterMap(undefined);
        setPlace('');
    }, [setPlace, resetEntity]);

    const closeEntityDetailed = useCallback(() => {
        resetSelectedEntity();
        clearOpeningHours();
        resetHoveredEntity();
        !closestEntity.length && setPlace('');
    }, [clearOpeningHours, closestEntity.length, setPlace, resetHoveredEntity, resetSelectedEntity]);

    useEffect(() => {
        if (selectedEntity) {
            resetEntity();
            clearOpeningHours();
            resetHoveredEntity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useBeforeunload(() => '');

    return (
        <>
            <Stepper step={STEPS.CHOOSE_STORE} />
            <Title>{t('choose_store')}</Title>
            <InputsStyled>
                <Autocomplete
                    isLoaded={isLoaded}
                    center={centerMap}
                    noClosestStore={results === 0}
                    setCenterMap={setCenterMap}
                    place={place}
                    setPlace={setPlace}
                />
                {!!selectedEntity ? (
                    <EntityDetailed closeEntityDetailed={closeEntityDetailed} />
                ) : (
                    <EntityList center={centerMap} handleClickReturn={handleClickReturn} />
                )}
            </InputsStyled>
            <Map isLoaded={isLoaded} center={centerMap} />
            {!!results && !selectedEntity && (
                <ButtonStyled
                    type="link"
                    onClick={handleClickReturn}
                    disabled={loadingClosestEntity}
                    icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
                >
                    {t('back_to_all_stores')}
                </ButtonStyled>
            )}
            {!!selectedEntity && (
                <ButtonMobileStyled
                    type="link"
                    onClick={closeEntityDetailed}
                    disabled={loadingClosestEntity}
                    icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
                >
                    {!!closestEntity.length ? t('back_to_search_results') : t('back_to_all_stores')}
                </ButtonMobileStyled>
            )}
        </>
    );
};
