import dayjs, { Dayjs } from 'dayjs';

import { DAY_MONTH_YEAR, DAY_NAME, HOUR_MINUTE } from 'const';
import type { DayjsInterval, Resource, ResourcesAvailability, StringInterval } from 'models';

export const getIntervalsIntersections = (firstList: DayjsInterval[], secondList: DayjsInterval[]): DayjsInterval[] => {
    let i = 0;
    let j = 0;
    const res = [];

    while (i < firstList.length && j < secondList.length) {
        const { start_at: fs, end_at: fe } = firstList[i]; // fs = start_at of first interval, fe = end of first interval
        const { start_at: ss, end_at: se } = secondList[j]; // ss = start_at of second interval, se = end of second interval
        const maxStart = dayjs.max(fs, ss);
        const minEnd = dayjs.min(fe, se);

        if (maxStart.isBefore(minEnd) || maxStart.isSame(minEnd)) res.push({ start_at: maxStart, end_at: minEnd });
        fe < se ? (i += 1) : (j += 1);
    }

    return res;
};

export const convertToDayJs = (interval: StringInterval): DayjsInterval => ({
    start_at: dayjs.tz(new Date(interval.start_at)),
    end_at: dayjs.tz(new Date(interval.end_at)),
});

export const cutSlots = (availabilities: StringInterval[] = [], interval: number, duration: number) => {
    return availabilities.reduce((acc: Record<string, StringInterval[]>, item) => {
        const start_at = dayjs.tz(new Date(item.start_at));
        const end_at = dayjs.tz(new Date(item.end_at));
        let currentStart = start_at;
        let currentEnd = start_at.add(duration, 'minutes');

        while (currentEnd.isBefore(end_at) || currentEnd.isSame(end_at)) {
            if (currentStart.isBefore(dayjs.tz(new Date())) || currentStart.isSame(dayjs.tz(new Date()))) {
                currentStart = currentStart.add(interval, 'minutes');
                currentEnd = currentStart.add(duration, 'minutes');
                continue;
            }
            if (currentStart.get('hour') < 12) {
                if (!acc['morning']) {
                    acc['morning'] = [];
                }
                acc['morning'].push({ start_at: currentStart.format(), end_at: currentEnd.format() });
            }
            if (currentStart.get('hour') >= 12 && currentStart.get('hour') < 18) {
                if (!acc['afternoon']) {
                    acc['afternoon'] = [];
                }
                acc['afternoon'].push({ start_at: currentStart.format(), end_at: currentEnd.format() });
            }
            if (currentStart.get('hour') >= 18) {
                if (!acc['evening']) {
                    acc['evening'] = [];
                }
                acc['evening'].push({ start_at: currentStart.format(), end_at: currentEnd.format() });
            }
            currentStart = currentStart.add(interval, 'minutes');
            currentEnd = currentStart.add(duration, 'minutes');
        }

        return acc;
    }, {});
};

export const getDuration = (values: Resource['resource_param_values']) => {
    const value = values.find((item) => item.param.name === 'duration')?.value;

    if (!value) {
        console.error('no duration value');
        return;
    }

    const dayjsValue = dayjs.tz(+value).utc();

    return (dayjsValue.valueOf() - dayjsValue.startOf('day').valueOf()) / 1000 / 60;
};

export const getInterval = (values: Resource['resource_param_values']) => {
    const value = values.find((item) => item.param.name === 'average-duration')?.value;

    if (!value) {
        console.error('no average-duration value');
        return;
    }

    return +value;
};

export const extractWorkWeek = (data: ResourcesAvailability[]): Record<string, StringInterval[]> => {
    return data.reduce((acc: Record<string, StringInterval[]>, item) => {
        const dayname = dayjs.tz(new Date(item.start_at)).format(DAY_NAME);

        if (!acc[dayname]) {
            acc[dayname] = [];
        }

        acc[dayname].push({
            start_at: dayjs.tz(new Date(item.start_at)).format(HOUR_MINUTE),
            end_at: dayjs.tz(new Date(item.end_at)).format(HOUR_MINUTE),
        });
        return acc;
    }, {});
};

export const monthChanged = (date: string, availabilities: Record<string, StringInterval[]>): boolean => {
    if (!availabilities || !Object.keys(availabilities).length) return true;
    const dateMonth = dayjs(date).get('month');
    const availabilityMonth = dayjs(Object.keys(availabilities)[0], DAY_MONTH_YEAR).get('month');
    return dateMonth !== availabilityMonth;
};

export const getFirstAvailableDate = (availabilities?: Record<string, StringInterval[]>) => {
    if (!availabilities) return;
    const [first] = Object.keys(availabilities).sort((a, b) => {
        const aDay = +a.split('/')[0];
        const bDay = +b.split('/')[0];

        return aDay - bDay;
    });

    return availabilities[first]?.[0].start_at;
};

export const isDayOutOfMoth = (value: Dayjs, date: Dayjs): boolean => {
    return !value.isSame(date, 'month');
};

export const capitalize = (value: string): string => `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`;

export const mapPhone = (string: string) => string.replace('+33 ', '0');

export const mergeOverlappingIntervals = (intervals: DayjsInterval[]): DayjsInterval[] => {
    if (intervals.length < 2) return intervals;
    intervals.sort((a, b) => a.start_at.valueOf() - b.start_at.valueOf());

    let merged = [intervals[0]];

    for (let i = 1; i < intervals.length; i++) {
        let lastMerged = merged[merged.length - 1];
        const { start_at: lastStart, end_at: lastEnd } = lastMerged;
        const { start_at: currentStart, end_at: currentEnd } = intervals[i];

        if (currentStart.isBefore(lastEnd)) {
            merged[merged.length - 1] = {
                start_at: lastStart,
                end_at: dayjs.max(lastEnd, currentEnd),
            };
        } else {
            merged.push(intervals[i]);
        }
    }

    return merged;
};

export const getStartAndEnd = (date: dayjs.Dayjs) => {
    let start = date.startOf('month');
    const end = date.endOf('month');
    const today = dayjs.tz(new Date());

    if (today.isSame(date, 'month')) {
        start = today.add(72, 'hours').startOf('day');
    } else if (start.diff(today, 'hours') < 72) {
        start = start.add(72 - start.diff(today, 'hours'), 'hours').startOf('day');
    }

    if (start.isAfter(end)) {
        start = end;
    }

    return { start_at_from: start.utc().format(), start_at_to: end.utc().format() };
};
