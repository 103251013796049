import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnchorLink from 'antd/lib/anchor/AnchorLink';
import { useSelector } from 'react-redux';

import { selectEntityLoading, selectSelectedEntity } from 'redux/selectors';
import { ReactComponent as PhoneIcon } from 'assets/phone.svg';
import { mapPhone } from 'utils';

import { StyledSpace, PhoneWrapper, TitleStyled, StyledSkeleton, TextStyled } from './EntityInfo.styled';

export const EntityInfo: FC = () => {
    const { t } = useTranslation();
    const entity = useSelector(selectSelectedEntity);
    const loading = useSelector(selectEntityLoading);

    return (
        <StyledSkeleton loading={loading} active>
            <StyledSpace className="entity-info">
                <TitleStyled level={4}>{`${t('shop')} ${entity?.label}`}</TitleStyled>
                <TextStyled>{entity?.address}</TextStyled>
                <TextStyled>{entity?.address_additional}</TextStyled>
                <TextStyled>
                    {entity?.zipcode} {entity?.town}
                </TextStyled>
                {entity?.phone && (
                    <PhoneWrapper>
                        <PhoneIcon />
                        <AnchorLink title={mapPhone(entity.phone)} href={`tel:${entity.phone}`} />
                    </PhoneWrapper>
                )}
            </StyledSpace>
        </StyledSkeleton>
    );
};
