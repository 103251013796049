import { AbstractAccessor } from 'sdkore';

export class CustomersAccessor extends AbstractAccessor {
    constructor() {
        super('/public/customers');
    }

    public async deleteCustomer(uuid: string) {
        return this.client.post(`${this.route}/${uuid}/anonymization`);
    }
}
