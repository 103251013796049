import styled from 'styled-components';
import React, { DOMAttributes } from 'react';
import List, { ListProps } from 'antd/lib/list';

export const ListStyled = styled<React.FC<ListProps<any> & { ['data-isallentities']: boolean } & DOMAttributes<{}>>>(
    List,
)`
    cursor: pointer;

    .ant-list-item-meta-avatar {
        padding-top: 4px;
        font-size: ${({ theme }) => `${theme.fontSizeXs}px`};
        font-weight: 500;
    }
    .ant-list-item-meta-title {
        font-size: ${({ theme }) => `${theme.fontSizeXs}px`} !important;
        font-weight: 500;
        margin: 4px 0;
    }
    .ant-list-item-action li svg {
        color: ${({ theme }) => theme.primary6};
    }
    .ant-list-item:hover {
        background-color: ${({ theme }) => theme.neutral2};
        color: ${({ theme }) => theme.primary6};
        .ant-list-item-meta-title,
        .ant-list-item-meta-description {
            color: ${({ theme }) => theme.primary6};
        }
    }
    .ant-list-header {
        text-align: right;
    }
    .ant-list-footer {
        display: flex;
        padding: 40px 0;
        justify-content: space-between;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 100%;
        display: ${({ 'data-isallentities': isAllEntities }) => (isAllEntities ? 'none' : 'block')};
        .ant-list-items .ant-list-item {
            padding: 12px !important;
        }
        .ant-list-header {
            text-align: left;
        }
        .ant-list-footer {
            margin-top: 15px;

            button {
                width: 100%;
            }
        }
    }
`;
