import React from 'react';
import styled from 'styled-components';
import Steps, { StepsProps } from 'antd/lib/steps';

export const StepsStyled = styled<React.FC<StepsProps>>(Steps)`
    position: sticky;
    top: 100px;
    z-index: 10;
    padding: 40px 0 15px;
    background: ${({ theme }) => theme.neutral1};
    box-shadow: ${({ theme }) => theme.boxShadow2};
    border-top: 1px solid rgb(153, 153, 153);

    .ant-steps-item-custom {
        .ant-steps-icon,
        .ant-steps-item-title {
            font-weight: 500;
        }
        .ant-steps-icon {
            padding-bottom: 16px;
        }
        .ant-steps-item-container {
            display: flex;
            align-items: center;
        }

        .ant-steps-item-title::after {
            background-color: ${({ theme }) => theme.neutral8} !important;
        }

        .ant-steps-item-icon {
            width: 24px !important;
            height: 24px !important;
            font-size: ${({ theme }) => theme.fontSizeXxxs}px;
            text-align: center;
            border-radius: 20px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .ant-steps-icon {
            height: 12px !important;
            line-height: initial !important;
            transform: translateY(1px) !important;
        }

        &.ant-steps-item-finish {
            .ant-steps-item-icon {
                background-color: ${({ theme }) => theme.neutral8} !important;
                border: 1px solid ${({ theme }) => theme.neutral8} !important;
            }
            .ant-steps-icon {
                color: ${({ theme }) => theme.neutral1} !important;
                font-size: ${({ theme }) => theme.fontSizeXxxs}px !important;
            }
        }

        &.ant-steps-item-active {
            .ant-steps-item-icon {
                background-color: ${({ theme }) => theme.primary6} !important;
                border: 1px solid ${({ theme }) => theme.primary6} !important;
            }
            .ant-steps-icon {
                color: ${({ theme }) => theme.neutral1} !important;
                font-size: ${({ theme }) => theme.fontSizeXxxs}px !important;
            }
            .ant-steps-item-title {
                color: ${({ theme }) => theme.primary6} !important;
            }
        }

        &.ant-steps-item-wait {
            .ant-steps-item-icon {
                background-color: ${({ theme }) => theme.neutral1} !important;
                border: 1px solid ${({ theme }) => theme.neutral8} !important;
            }
            .ant-steps-icon {
                color: ${({ theme }) => theme.neutral8} !important;
                font-size: ${({ theme }) => theme.fontSizeXxxs}px !important;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        top: 87px;
        padding: 10px 0;

        .ant-steps-item-content {
            display: none;
        }
        .ant-steps-item-icon {
            z-index: 10;
        }
        .ant-steps-item:not(:last-child) .ant-steps-item-icon:after {
            position: absolute;
            display: block;
            content: '';
            z-index: 0;
            top: 11px;
            inset-inline-start: 45px;
            width: 100%;
            height: 1.1px;
            background: ${({ theme }) => theme.neutral8};
        }

        .ant-steps-item:first-child .ant-steps-item-icon:after {
            inset-inline-start: 30px;
        }
    }
`;
