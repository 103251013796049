import { fork } from 'redux-saga/effects';

import watchInit from './sagaInitial';
import watchEntity from './sagaEntity';
import watchEntities from './sagaEntities';
import watchOpeningHours from './sagaOpeningHours';
import watchAppointment from './sagaAppointment';
import watchCalendar from './sagaCalendar';
import watchVerification from './sagaVerification';
import watchAppointmentDetails from './sagaAppointmentDetails';

export default function* rootSaga() {
    yield fork(watchInit);
    yield fork(watchEntity);
    yield fork(watchEntities);
    yield fork(watchOpeningHours);
    yield fork(watchAppointment);
    yield fork(watchCalendar);
    yield fork(watchVerification);
    yield fork(watchAppointmentDetails);
}
