import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export const useLangNavigate = () => {
    const { i18n } = useTranslation();
    const navigateI18N = useNavigate();

    const navigate = useCallback(
        (route: string) => {
            navigateI18N(`/${i18n.language}/${route}`);
        },
        [i18n.language, navigateI18N],
    );

    return navigate;
};
