import styled from 'styled-components';
import { Calendar } from './Calendar';

export const StyledCalendar = styled(Calendar)`
    .ant-picker-content {
        tbody {
            &:before {
                content: '';
                display: block;
                line-height: 10px;
                height: 16px;
            }
        }
    }

    .ant-picker-panel {
        border: none !important;
    }

    .ant-picker-calendar-date-value {
        font-weight: 500;
    }

    .ant-picker-cell {
        &::before {
            display: none;
        }
        padding: 0;
        span {
            font-weight: 500;
            font-size: ${({ theme }) => theme.fontSizeSm}px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 4px auto;
            width: 42px;
            height: 42px;
        }
    }
    .ant-picker-cell-disabled {
        span {
            color: #c3c3c3 !important;
        }
    }
`;
