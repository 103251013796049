import { AbstractAccessor } from 'sdkore';

export class AssignmentsAccessor extends AbstractAccessor {
    constructor() {
        super('/public/booking_management/assignments');
    }

    public async getActiveAppointments(entityUuid: string) {
        const query = await this.buildQueryString({
            max_use: true,
            resourceType: 'appointment',
            enable: true,
        });

        return this.client.get(`${this.route}/entity/${entityUuid}?${query}`);
    }
}
