import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import { Icon } from '@wynd-bo/keystone-icons';
import { Rule } from 'rc-field-form/lib/interface';

import { selectDuration, selectContactInfo, selectStartTime } from 'redux/selectors';
import { contactInfoActions } from 'redux/slices';
import { ROUTE_DATE_AND_HOUR, COMMENT_SIZE, ROUTE_VERIFICATION, PHONE_MAX, EMAIL_MAX, ROUTE_CHOOSE_STORE } from 'const';
import { useActions, useLangNavigate } from 'hooks';
import type { ContactInformation } from 'models';
import { AppointmentInfo, EntityInfo } from 'components';

import { checkRecaptcha } from 'tools';
import {
    ButtonWrapper,
    TextStyled,
    ItemStyled,
    InfoWrapper,
    ContentWrapper,
    FormWrapper,
    LastNameInput,
    FormStyled,
    InputStyled,
    ChangeStoreButton,
    TextAreaStyled,
    CommentTextItem,
    GdprWrapper,
    ReCaptchaError,
} from './ContactForm.styled';
import Checkbox from 'antd/lib/checkbox';

const onlyDigits = new RegExp(/^\d+$/);
const onlyLetters = new RegExp(/^[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ -]+$/);

export interface ContactFormProps {
    googleReCaptchaKey?: string;
}

export const ContactForm: React.FC<ContactFormProps> = ({ googleReCaptchaKey }) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();

    const [loading, setLoading] = useState(false);

    const contactInfo = useSelector(selectContactInfo);
    const start_at = useSelector(selectStartTime)!;
    const duration = useSelector(selectDuration)!;
    const { setContactInfo } = useActions(contactInfoActions);

    const [form] = Form.useForm<ContactInformation>();
    const comment = Form.useWatch('comment', form);

    const { getFieldsValue, getFieldsError } = form;
    const [hasReCaptchaError, setHasReCaptchaError] = useState(false);

    const ruleName: Rule[] = [
        { required: true, message: t('enter_firstname') },
        {
            validator: (_, value) =>
                !value || onlyLetters.test(value)
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('enter_valid_firstname'))),
        },
    ];
    const ruleLastname: Rule[] = [
        { required: true, message: t('enter_lastname') },
        {
            validator: (_, value) =>
                !value || onlyLetters.test(value)
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('enter_valid_firstname'))),
        },
    ];
    const rulePhone: Rule[] = [
        { required: true, message: t('enter_phone') },
        {
            type: 'string',
            max: PHONE_MAX,
            min: PHONE_MAX,
            message: t('phone_max_count', { count: PHONE_MAX }),
        },
        {
            validator: (_, value) =>
                !value || onlyDigits.test(value)
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('enter_valid_phone'))),
        },
    ];
    const ruleEmail: Rule[] = [
        {
            required: true,
            message: t('enter_email'),
        },
        {
            type: 'email',
            message: t('enter_valid_email'),
        },
        {
            type: 'string',
            max: EMAIL_MAX,
            message: t('email_max_count', { count: EMAIL_MAX }),
        },
    ];
    const ruleCheckbox: Rule[] = [
        {
            validator: async (_, checked) =>
                checked === true ? Promise.resolve() : Promise.reject(new Error(t('enter_gdpr'))),
        },
    ];

    const saveForm = useCallback(() => {
        if (!!getFieldsError().filter(({ errors }) => errors.length).length) {
            return;
        }
        const formValues = getFieldsValue();
        setContactInfo(formValues);
    }, [getFieldsValue, getFieldsError, setContactInfo]);

    const onClickGoBack = useCallback(() => {
        saveForm();
        navigate(ROUTE_DATE_AND_HOUR);
    }, [navigate, saveForm]);

    const goClickChangeStore = useCallback(() => {
        saveForm();
        navigate(ROUTE_CHOOSE_STORE);
    }, [navigate, saveForm]);

    const onFinish = useCallback(() => {
        setLoading(true);
        setHasReCaptchaError(false);
        checkRecaptcha(
            () => {
                saveForm();
                navigate(ROUTE_VERIFICATION);
            },
            () => {
                setLoading(false);
                setHasReCaptchaError(true);
            },
            'contact_form',
            googleReCaptchaKey,
        );
    }, [googleReCaptchaKey, navigate, saveForm]);

    useEffect(() => {
        document.body.classList.add('contact_form');
        return () => document.body.classList.remove('contact_form');
    }, []);

    const {
        i18n: { language },
    } = useTranslation();

    return (
        <FormStyled form={form} layout="vertical" autoComplete="off" initialValues={contactInfo} onFinish={onFinish}>
            <Form.Item noStyle shouldUpdate>
                {() => (
                    <>
                        <ContentWrapper>
                            <FormWrapper>
                                <Form.Item name="firstname" rules={ruleName}>
                                    <InputStyled size="large" maxLength={50} placeholder={`${t('firstname')}*`} />
                                </Form.Item>
                                <Form.Item name="lastname" rules={ruleLastname}>
                                    <LastNameInput size="large" maxLength={50} placeholder={`${t('lastname')}*`} />
                                </Form.Item>
                                <Form.Item name="phone" rules={rulePhone}>
                                    <InputStyled
                                        size="large"
                                        min={0}
                                        maxLength={PHONE_MAX}
                                        placeholder={`${t('phone')}*`}
                                    />
                                </Form.Item>
                                <Form.Item name="email" rules={ruleEmail}>
                                    <InputStyled size="large" placeholder={`${t('email')}*`} />
                                </Form.Item>
                                <CommentTextItem>
                                    <Text>{t('your_comment_will_allow')}</Text>
                                </CommentTextItem>
                                <Form.Item>
                                    <ItemStyled name="comment">
                                        <TextAreaStyled
                                            size="large"
                                            rows={4}
                                            maxLength={COMMENT_SIZE}
                                            placeholder={t('your_comment')}
                                        />
                                    </ItemStyled>
                                    <TextStyled>
                                        {t('characters_left', {
                                            count: COMMENT_SIZE - (comment?.length || 0),
                                        })}
                                    </TextStyled>
                                </Form.Item>
                                <GdprWrapper name="gdpr" rules={ruleCheckbox} valuePropName="checked">
                                    <Checkbox aria-labelledby="gdpr-text">
                                        <Text id="gdpr-text">
                                            <Trans i18nKey="gdpr">
                                                I have read and understood
                                                <a
                                                    href={'https://www.poltronesofa.com/' + language + '/Privacy'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    the information
                                                </a>
                                                in accordance with Articles 13 and 14 of EU Regulation 206/679
                                            </Trans>
                                        </Text>
                                    </Checkbox>
                                </GdprWrapper>
                            </FormWrapper>
                            <InfoWrapper>
                                <AppointmentInfo
                                    saveForm={saveForm}
                                    start_at={start_at}
                                    duration={duration}
                                    showButton
                                />
                                <EntityInfo />
                                <ChangeStoreButton
                                    size="large"
                                    type="link"
                                    onClick={goClickChangeStore}
                                    icon={<Icon size="sm" name="chevron-left" verticalAlign="text-bottom" />}
                                >
                                    {t('change_store')}
                                </ChangeStoreButton>
                            </InfoWrapper>
                        </ContentWrapper>

                        {hasReCaptchaError && <ReCaptchaError>{t('recaptcha_error')}</ReCaptchaError>}

                        <ButtonWrapper>
                            <Button
                                type="link"
                                size="large"
                                onClick={onClickGoBack}
                                icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
                            >
                                {t('return')}
                            </Button>
                            <Button type="primary" size="large" htmlType="submit" disabled={loading} loading={loading}>
                                {t('continue')}
                                <Icon size="sm" name="arrow-right" verticalAlign="text-bottom" />
                            </Button>
                        </ButtonWrapper>
                    </>
                )}
            </Form.Item>
        </FormStyled>
    );
};
