import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

export const StyledText = styled(Text)`
    font-size: 14px;
    display: flex;
`;

export const StyledDay = styled.span`
    width: 80px;
    margin-right: 10px;
    text-transform: capitalize;
`;

export const StyledSlots = styled.span`
    width: 100px;
    display: flex;
    flex-direction: column;

    span {
        white-space: nowrap;
    }
`;
