import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';

import type { RootState, StringInterval } from 'models';
import { defaultState } from '../store/initialStates';

type CalendarState = RootState['calendar'];

interface CalendarSlice extends SliceCaseReducers<CalendarState> {
    getAvailabilities: CaseReducer<CalendarState, PayloadAction<{ visibleDate: string; date?: string }>>;
    getAvailabilitiesSuccess: CaseReducer<CalendarState, PayloadAction<Record<string, StringInterval[]>>>;
    getAvailabilitiesFailure: CaseReducer<CalendarState>;
    setSlots: CaseReducer<CalendarState, PayloadAction<Record<string, StringInterval[]>>>;
    pickSlot: CaseReducer<CalendarState, PayloadAction<StringInterval>>;
    clearCalendar: CaseReducer<CalendarState>;
    disableLoader: CaseReducer<CalendarState>;
}

export const sliceCalendar = createSlice<CalendarState, CalendarSlice>({
    initialState: defaultState.calendar,
    name: '@@calendar',
    reducers: {
        getAvailabilities: (state, { payload }) => {
            state.loading = true;
            state.visibleDate = payload.visibleDate;
            state.pickedSlot = undefined;
            state.date = payload.date;
        },
        getAvailabilitiesSuccess: (state, { payload }) => {
            state.availabilities = payload;
        },
        getAvailabilitiesFailure: (state) => {
            state.loading = false;
        },
        setSlots: (state, { payload }) => {
            state.slots = payload;
            state.pickedSlot = undefined;
        },
        pickSlot: (state, { payload }) => {
            state.pickedSlot = payload;
        },
        clearCalendar: (state) => {
            state.date = defaultState.calendar.date;
            state.availabilities = defaultState.calendar.availabilities;
            state.slots = defaultState.calendar.slots;
            state.loading = defaultState.calendar.loading;
        },
        disableLoader: (state) => {
            state.loading = false;
        },
    },
});

export const calendarActions = sliceCalendar.actions;

export const {
    getAvailabilities,
    getAvailabilitiesSuccess,
    getAvailabilitiesFailure,
    setSlots,
    pickSlot,
    clearCalendar,
    disableLoader,
} = sliceCalendar.actions;
