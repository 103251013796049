import styled from 'styled-components';
import Skeleton from 'antd/lib/skeleton';
import Title from 'antd/lib/typography/Title';

export const StyledSkeleton = styled(Skeleton)`
    width: 305px;
`;

export const TitleStyled = styled(Title)`
    margin-bottom: 0 !important;
`;
