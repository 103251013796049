import React from 'react';
import styled from 'styled-components';

import Button, { ButtonProps } from 'antd/lib/button';

export const ContentStyled = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;
    }
`;
export const InputsStyled = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 100%;
    }
`;

export const ButtonMobileStyled = styled<React.FC<ButtonProps>>(Button)`
    display: none;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
    }
`;

export const ButtonStyled = styled<React.FC<ButtonProps>>(Button)`
    margin-top: 40px;
    margin-left: 25px;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        display: block;
        width: 100%;
        margin-left: 0;
    }
`;
