import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { ClientResponse } from 'sdkore';
import dayjs from 'dayjs';

import type { AvailableResourcesResponse, Schedule, Customer } from 'models';
import { StatusTypes } from 'models';
import {
    selectAppointment,
    selectContactInfo,
    selectEntity,
    selectPickedSlot,
    selectSelectedEntity,
    selectTimezone,
    selectVerification,
} from 'redux/selectors';
import { customersAccessor, resourcesAccessor, schedulesAccessor, showNoServiceError } from 'utils';

import {
    createAppointment,
    createAppointmentSuccess,
    createAppointmentFailure,
    setScheduleBlockTag,
    setCustomerUuid,
    getAvailableResources,
    getAvailableResourcesSuccess,
    getAvailableResourcesFailure,
    cleanUp,
    cleanUpSuccess,
    cleanUpFailure,
} from '../slices';

function* cleanUpSaga() {
    try {
        const { customerUuid, blockTag } = yield select(selectVerification);
        if (customerUuid) {
            yield call(() => customersAccessor.deleteCustomer(customerUuid));
        }
        if (blockTag) {
            yield call(() => schedulesAccessor.remove(blockTag));
        }
        yield put(cleanUpSuccess());
    } catch {
        yield put(cleanUpFailure());
    }
}

function* createAppointmentSaga({ payload }: ReturnType<typeof createAppointment>) {
    try {
        const { onSuccess } = payload;
        const appointment: ReturnType<typeof selectAppointment> = yield select(selectAppointment);
        const slot: ReturnType<typeof selectPickedSlot> = yield select(selectPickedSlot);
        const entity: ReturnType<typeof selectEntity> = yield select(selectEntity);
        const timezone: ReturnType<typeof selectTimezone> = yield select(selectTimezone);
        const contactInfo: ReturnType<typeof selectContactInfo> = yield select(selectContactInfo);
        const verification: ReturnType<typeof selectVerification> = yield select(selectVerification);

        const schedule: Schedule = yield call(() =>
            schedulesAccessor.create({
                booking: {
                    entity: { id: entity.id },
                    resource: { uuid: appointment!.uuid },
                    label: `${contactInfo.firstname} ${contactInfo.lastname}`,
                    product_volume: 1,
                    state: 'enabled',
                    comment: contactInfo.comment,
                    status: {
                        uuid: StatusTypes.OPEN,
                    },
                    unlimited: true,
                    booking_details: [
                        {
                            timezone,
                            resource: { uuid: appointment!.uuid },
                            status: {
                                uuid: StatusTypes.OPEN,
                            },
                            start_at: slot!.start_at,
                            end_at: slot!.end_at,
                        },
                    ],
                },
                quantity: 1,
            }),
        );

        yield put(setScheduleBlockTag(schedule.booking.block_tag));

        const resource: ClientResponse<{ failed_affectation?: string[] }> = yield call(() =>
            schedulesAccessor.assignResourceToSchedule(schedule.booking.block_tag, verification.resource?.uuid!),
        );

        if (resource.data.failed_affectation) {
            console.error('no-resource');
            return;
        }

        const customer: Customer = yield call(() =>
            customersAccessor.create({
                ...contactInfo,
                is_active: true,
                is_guest: true,
                login: contactInfo.email,
                entity: { id: entity.id },
                original_entity: { id: entity.id },
            }),
        );
        yield put(setCustomerUuid(customer.uuid));
        yield call(() => schedulesAccessor.makeBooking(schedule.booking.block_tag, customer.uuid));
        yield put(createAppointmentSuccess());
        yield call(onSuccess);
    } catch (error: any) {
        const { onError } = payload;
        if (error.message === 'no-resource') {
            yield call(onError);
        } else {
            yield call(showNoServiceError);
        }
        yield put(createAppointmentFailure());
        yield put(cleanUp());
    }
}

export function* getAvailableResourcesSaga({ payload }: ReturnType<typeof getAvailableResources>) {
    try {
        const date: ReturnType<typeof selectPickedSlot> = yield select(selectPickedSlot);
        const entity: ReturnType<typeof selectSelectedEntity> = yield select(selectSelectedEntity);
        const appointment: ReturnType<typeof selectAppointment> = yield select(selectAppointment);

        if (!date) {
            console.error('no-date');
            return;
        }
        if (!entity) {
            console.error('no-entity');
            return;
        }
        if (!appointment) {
            console.error('no-appointment');
            return;
        }

        const { data }: ClientResponse<AvailableResourcesResponse> = yield call(() =>
            resourcesAccessor.getAvailableResources(
                dayjs.tz(new Date(date.start_at)).utc().format(),
                dayjs.tz(new Date(date.end_at)).utc().format(),
                entity.id as number,
                appointment?.uuid,
            ),
        );
        const [firstAvailableResource] = data.available;
        if (!firstAvailableResource) {
            console.error('no-resource');
            return;
        }
        yield put(getAvailableResourcesSuccess(firstAvailableResource));
    } catch (error: any) {
        const { onError } = payload;
        yield call(onError);
        yield put(getAvailableResourcesFailure());
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(createAppointment.type, createAppointmentSaga);
    yield takeLatest(getAvailableResources.type, getAvailableResourcesSaga);
    yield takeLatest(cleanUp.type, cleanUpSaga);
}
