import React, { useEffect, useLayoutEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    ROUTE_CHOOSE_STORE,
    ROUTE_DATE_AND_HOUR,
    DEFAULT_LANGUAGE,
    LANGUAGES,
    ROUTE_CONFIRMATION,
    ROUTE_CONTACT_INFORMATION,
    ROUTE_VERIFICATION,
    ROUTE_APPOINTMENT_DETAILS,
} from 'const';
import {
    ChooseStore,
    DateAndHour,
    Confirmation,
    Error404,
    ContactInfo,
    Verification,
    AppointmentDetails,
} from 'containers';
import { Layout, Spinner } from 'components';
import { useActions } from 'hooks';
import type { DynamicConfig } from 'models';
import { initialActions } from 'redux/slices';
import { initDayjs } from 'utils/dayjs';
import { selectInitial } from 'redux/selectors';

export const Routing: React.FC = () => {
    /**
     * Loading of the dynamic configuration
     */
    const [{ data, loading, error }] = useAxios<DynamicConfig>('/config.json');
    const { initAction } = useActions(initialActions);
    const { loading: initialLoading, initialized } = useSelector(selectInitial);
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const languageShort = language.split('-')[0];
        initDayjs(languageShort);
    }, [language]);

    useEffect(() => {
        data && initAction(data);
    }, [initAction, data]);

    useEffect(() => {
        // Add reCaptcha
        if (data?.googleReCaptchaKey) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=' + data?.googleReCaptchaKey;
            document.head.appendChild(script);
        }
    }, [data?.googleReCaptchaKey]);

    useEffect(() => {
        // Add oneTrust banner
        if (data?.oneTrustKey) {
            const script = document.createElement('script');
            script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
            script.type = 'text/javascript';
            script.setAttribute('charset', 'UTF-8');
            script.setAttribute('data-domain-script', data?.oneTrustKey);
            document.head.insertBefore(script, document.getElementById('oneTrust'));
        }
    }, [data?.oneTrustKey]);

    if (loading) return <Spinner tip={t('loading')} />;
    if (error) return <p>Error on loading config.json !</p>;
    if (!initialized) return <Spinner tip="Initializing..." />;
    if (!initialized && !initialLoading) return <p>Initialization error</p>;

    return (
        <Routes>
            {LANGUAGES.map((language) => (
                <Route path={language} key={language}>
                    <Route element={<Layout />}>
                        <Route path={ROUTE_CHOOSE_STORE} element={<ChooseStore googleApiKey={data?.googleApiKey} />} />
                        <Route path={ROUTE_DATE_AND_HOUR} element={<DateAndHour />} />
                        <Route path={ROUTE_CONFIRMATION} element={<Confirmation />} />
                        <Route
                            path={ROUTE_CONTACT_INFORMATION}
                            element={<ContactInfo googleReCaptchaKey={data?.googleReCaptchaKey} />}
                        />
                        <Route path={ROUTE_VERIFICATION} element={<Verification />} />
                        <Route path="*" element={<Error404 />} />
                    </Route>
                    <Route element={<Layout showLogoOnly />}>
                        <Route path={ROUTE_APPOINTMENT_DETAILS} element={<AppointmentDetails />} />
                    </Route>
                </Route>
            ))}
            <Route path="*" element={<Navigate to={`${DEFAULT_LANGUAGE}/${ROUTE_CHOOSE_STORE}`} />} />
        </Routes>
    );
};
