import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import { useBeforeunload } from 'react-beforeunload';

import { Stepper } from 'components';
import { useLangNavigate } from 'hooks';
import { ROUTE_CHOOSE_STORE, STEPS } from 'const';
import { selectEntityId } from 'redux/selectors';

import { ContactForm } from '../ContactForm';

export interface ContactInfoProps {
    googleReCaptchaKey?: string;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ googleReCaptchaKey }) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const entityId = useSelector(selectEntityId);

    useEffect(() => {
        !entityId && navigate(ROUTE_CHOOSE_STORE);
    }, [navigate, entityId]);

    useBeforeunload(() => '');

    return (
        <div>
            <Stepper step={STEPS.CONTACT_INFORMATION} />
            <Title>{t('your_contact_information')}</Title>
            <ContactForm googleReCaptchaKey={googleReCaptchaKey} />
        </div>
    );
};
