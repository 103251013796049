import { AbstractAccessor } from 'sdkore';

import { TypeUuids } from 'models';

export class ResourcesAccessor extends AbstractAccessor {
    constructor() {
        super('/public/booking_management/resources');
    }

    public async getEntityStores(entityUuid: string) {
        return this.findAllByCriteria({
            entities: [entityUuid],
            resource_type: TypeUuids.SHOP,
        });
    }

    public async getAssignments(entityUuid: string) {
        return this.findAllByCriteria({
            entities: [entityUuid],
            resource_type_parent: TypeUuids.ASSIGNMENT,
            state: 'enabled',
        });
    }

    public async getAvailableResources(
        start_at: string,
        end_at: string,
        entities: number,
        compatible_assignments: string,
    ) {
        const query = await this.buildQueryString({
            start_at,
            end_at,
            entities: [entities],
            compatible_assignments: [compatible_assignments],
        });
        return this.client.get(`${this.route}/available?${query}`);
    }
}
