import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';

import { DEFAULT_MAP_CENTER } from 'const';
import { useWindowDimensions } from 'hooks';
import {
    getEntitiesCoordinates,
    getAllEntitiesCoordinates,
    selectHoveredEntities,
    selectSelectedEntityId,
} from 'redux/selectors';

import { GoogleMapWrapper } from './Map.styled';
import { CustomMarker } from '../CustomMarker';

const defaultOptions: google.maps.MapOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
};

interface MapProps {
    isLoaded: boolean;
    center?: google.maps.LatLngLiteral;
}

export const Map: React.FC<MapProps> = ({ isLoaded, center }) => {
    const { width } = useWindowDimensions();
    const [defaultCenter, setDefaultCenter] = useState(5);

    const mapRef = useRef<google.maps.Map>();
    const entitiesCoordinates = useSelector(getEntitiesCoordinates);
    const allCoordinates = useSelector(getAllEntitiesCoordinates);
    const selectedEntityId = useSelector(selectSelectedEntityId);
    const hoveredEntities = useSelector(selectHoveredEntities);

    useEffect(() => {
        if (width > 1290) {
            setDefaultCenter(5.8);
        } else if (width <= 530) {
            setDefaultCenter(4.8);
        } else if (width <= 768) {
            setDefaultCenter(5.6);
        } else if (width <= 860) {
            setDefaultCenter(4.8);
        } else if (width <= 1100) {
            setDefaultCenter(5);
        } else if (width <= 1290) {
            setDefaultCenter(5.5);
        }
    }, [width]);

    useEffect(() => {
        // we trigger this hook when user go back from entity details page by checking selectedEntityId
        if (!mapRef.current || !!selectedEntityId) return;
        const bounds = new window.google.maps.LatLngBounds();
        !!center && bounds.extend(center);
        entitiesCoordinates.forEach((location) => {
            bounds.extend({ lat: location.position.lat, lng: location.position.lng });
        });
        if (!entitiesCoordinates.length) {
            allCoordinates.forEach((location) => {
                bounds.extend({ lat: location.position.lat, lng: location.position.lng });
            });
        }
        mapRef.current.fitBounds(bounds);
    }, [entitiesCoordinates, allCoordinates, center, selectedEntityId]);

    const onLoad = React.useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = React.useCallback(() => {
        mapRef.current = undefined;
    }, []);

    return isLoaded ? (
        <GoogleMapWrapper>
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                }}
                center={center || DEFAULT_MAP_CENTER}
                zoom={center ? 14 : defaultCenter}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={defaultOptions}
            >
                {center && <Marker position={center} icon="/marker-point.svg" />}
                <MarkerClusterer
                    styles={[{ url: '/clusterer.svg', height: 40, width: 40, textColor: '#fff', textSize: 14 }]}
                >
                    {(clusterer) => (
                        <>
                            {!!entitiesCoordinates.length
                                ? entitiesCoordinates?.map(({ position, id }, index) => (
                                      <CustomMarker
                                          id={id}
                                          clusterer={clusterer}
                                          position={position}
                                          isSelected={id === hoveredEntities}
                                          key={index}
                                      />
                                  ))
                                : allCoordinates?.map(({ position, id }, index) => (
                                      <CustomMarker
                                          id={id}
                                          clusterer={clusterer}
                                          position={position}
                                          isSelected={id === hoveredEntities}
                                          key={index}
                                      />
                                  ))}
                        </>
                    )}
                </MarkerClusterer>
            </GoogleMap>
        </GoogleMapWrapper>
    ) : (
        <></>
    );
};
