import axios, { AxiosRequestConfig } from 'axios';
import {
    AbstractClient,
    ClientParameters,
    ClientResponse,
    HTTPMethod,
    RequestException,
    RequestHeaderObject,
    SDKException,
} from 'sdkore';

/**
 * Client usable without being authenticate (tokenless)
 */
export class AnonymousClient extends AbstractClient {
    constructor(params: ClientParameters) {
        super(params);
        this.removeVoidToken();
    }

    /**
     * @inheritdoc
     */
    protected getToken(): Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    /**
     * Remove Authorization token if value is null
     */
    private removeVoidToken() {
        this.axios.interceptors.request.use((config) => ({
            ...config,
            transformRequest:
                !config.headers.common.Authorization && !config.url?.includes('/authenticate')
                    ? [
                          (data) => {
                              delete config.headers.common.Authorization;
                              return data;
                          },
                      ]
                    : config.transformRequest,
        }));
    }

    /**
     * @inheritdoc
     */
    public async request<T>(
        url: string,
        method: HTTPMethod,
        data?: unknown,
        headers?: RequestHeaderObject,
        config: AxiosRequestConfig = {},
    ): Promise<ClientResponse<T>> {
        try {
            const response = await this.axios.request<T>({
                data: JSON.stringify(data),
                headers,
                method,
                url,
                cancelToken: new axios.CancelToken((c) => {
                    this.canceler = c;
                }),
                ...config,
            });

            const res: ClientResponse<T> = {
                ...response,
                getHeader: (header: string) =>
                    res.headers[header] || res.headers[String.prototype.toLowerCase.apply(header)],
            };

            return res;
        } catch (e: any) {
            /* istanbul ignore next: browser specific checking */
            if (!e.response) {
                throw new SDKException('Unknown error.', e);
            }
            throw new RequestException('Unexpected response.', e, e.response.status, e.response.data);
        }
    }
}
