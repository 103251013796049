import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/lib/divider';
import Title from 'antd/lib/typography/Title';
import { Icon } from '@wynd-bo/keystone-icons';
import { useBeforeunload } from 'react-beforeunload';

import {
    selectCalendarLoading,
    selectEntityId,
    selectEntityLoading,
    selectPickedSlot,
    selectSelectedEntity,
    selectSlots,
} from 'redux/selectors';
import { calendarActions, entityActions, openingHoursActions } from 'redux/slices';
import { ROUTE_CHOOSE_STORE, ROUTE_CONTACT_INFORMATION, STEPS } from 'const';
import {
    TimeSlot,
    TimeGroup,
    Stepper,
    EntityInfo,
    AvailabilitiesCalendar,
    SkeletonTime,
    EntityHours,
} from 'components';
import { useActions, useLangNavigate } from 'hooks';
import type { StringInterval } from 'models';

import { ButtonStyled, ButtonBlock, CalendarWrapper, TimeWrapper, EntityWrapper, NoSlots } from './DateAndHour.styled';

export const DateAndHour = () => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const [searchParams] = useSearchParams();
    const storeId = searchParams.get('storeId');
    const slots = useSelector(selectSlots);
    const pickedSlot = useSelector(selectPickedSlot);
    const loading = useSelector(selectCalendarLoading);
    const id = useSelector(selectEntityId);
    const entityLoading = useSelector(selectEntityLoading);
    const selectedEntity = useSelector(selectSelectedEntity);

    const { clearOpeningHours } = useActions(openingHoursActions);
    const { pickSlot, clearCalendar } = useActions(calendarActions);
    const { getEntity, getEntityByStoreId } = useActions(entityActions);

    useEffect(() => {
        if (storeId) {
            getEntityByStoreId({ id: storeId, navigate });
            return;
        }
        if (id) {
            id !== selectedEntity?.id && getEntity(id);
            return;
        }
        navigate(ROUTE_CHOOSE_STORE);
    }, [id, getEntity, getEntityByStoreId, navigate, storeId, selectedEntity?.id]);

    const goBack = useCallback(() => {
        navigate(ROUTE_CHOOSE_STORE);
        clearCalendar();
        clearOpeningHours();
    }, [navigate, clearCalendar, clearOpeningHours]);

    const onClickTimeSlot = useCallback(
        (value: StringInterval) => {
            navigate(ROUTE_CONTACT_INFORMATION);
            pickSlot(value);
        },
        [navigate, pickSlot],
    );
    useBeforeunload(() => '');

    const arraySlots = useMemo(() => Object.entries(slots), [slots]);

    const isLoading = loading || entityLoading;

    return (
        <div>
            <Stepper step={STEPS.DATE_AND_HOUR} />
            <div>
                <Title>{t('choose_date_time')}</Title>
                <EntityWrapper>
                    <EntityInfo />
                    <EntityHours />
                </EntityWrapper>
                <ButtonBlock
                    size="large"
                    type="link"
                    onClick={goBack}
                    icon={<Icon size="sm" name="arrow-left" verticalAlign="text-top" />}
                >
                    {t('change_store')}
                </ButtonBlock>
                <Divider />
                <CalendarWrapper>
                    <AvailabilitiesCalendar />
                    <TimeWrapper>
                        {isLoading && <SkeletonTime />}
                        {!arraySlots.length && !isLoading ? (
                            <NoSlots>{t('no_slots_available')}</NoSlots>
                        ) : (
                            arraySlots.map(([key, slots]) => (
                                <TimeGroup key={key} name={key}>
                                    {slots.map((slot) => (
                                        <TimeSlot
                                            key={slot.start_at}
                                            selected={pickedSlot?.start_at === slot.start_at}
                                            value={slot}
                                            clickSlot={onClickTimeSlot}
                                        />
                                    ))}
                                </TimeGroup>
                            ))
                        )}
                    </TimeWrapper>
                </CalendarWrapper>
                <ButtonStyled
                    type="link"
                    onClick={goBack}
                    icon={<Icon size="sm" name="arrow-left" verticalAlign="text-bottom" />}
                >
                    {t('return')}
                </ButtonStyled>
            </div>
        </div>
    );
};
