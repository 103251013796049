import dayjs from 'dayjs';
import React, { FC, useCallback } from 'react';

import type { StringInterval } from 'models';
import { HOUR_MINUTE } from 'const';
import { StyledButton } from './TimeSlot.styled';

interface TimeSlotProps {
    value: StringInterval;
    selected: boolean;
    clickSlot: (data: StringInterval) => void;
}

export const TimeSlot: FC<TimeSlotProps> = ({ value, selected, clickSlot }) => {
    const start_at = dayjs.tz(new Date(value.start_at)).format(HOUR_MINUTE);
    const end_at = dayjs.tz(new Date(value.end_at)).format(HOUR_MINUTE);

    const onClick = useCallback(() => {
        clickSlot(value);
    }, [clickSlot, value]);

    return (
        <StyledButton size="large" onClick={onClick} selected={selected} data-testid={`slot-${value.start_at}`}>
            {start_at} - {end_at}
        </StyledButton>
    );
};
