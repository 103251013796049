import React from 'react';
import Spin from 'antd/lib/spin';

import { Wrapper } from './Spinner.styled';

interface SpinnerProps {
    tip?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ tip }) => (
    <Wrapper>
        <Spin tip={tip} size="large" />
    </Wrapper>
);
