import React from 'react';
import { useSelector } from 'react-redux';
import List from 'antd/lib/list';
import Skeleton from 'antd/lib/skeleton';
import { Icon } from '@wynd-bo/keystone-icons';
import { useTranslation } from 'react-i18next';

import { selectEntitiesLoading } from 'redux/selectors';
import { entityActions, entitiesActions } from 'redux/slices';
import { useActions, useLangNavigate } from 'hooks';
import { ROUTE_DATE_AND_HOUR } from 'const';
import { Entity } from 'models';

import { TownStyled } from './EntityItem.styled';

interface EntityItemProps {
    entity: Entity;
    index: number;
}

export const EntityItem: React.FC<EntityItemProps> = React.memo(({ entity, index }) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const { setEntityId } = useActions(entityActions);
    const { setEntityIsChosen } = useActions(entitiesActions);
    const loading = useSelector(selectEntitiesLoading);

    return (
        <List.Item
            actions={[<Icon name="arrow-right" size="sm" />]}
            onClick={() => {
                setEntityId(entity.id);
                navigate(ROUTE_DATE_AND_HOUR);
            }}
            onMouseOver={() => setEntityIsChosen(entity.id)}
        >
            <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                    avatar={index + 1}
                    title={`${t('shop')} ${entity.label}`}
                    description={
                        <>
                            <div>{entity.address}</div>
                            <div>
                                {entity.zipcode} <TownStyled>{entity.town}</TownStyled>
                            </div>
                        </>
                    }
                />
            </Skeleton>
        </List.Item>
    );
});
