import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Modal, { ModalProps } from 'antd/lib/modal';

import type { sofaTheme } from 'const';
import Spin from 'antd/lib/spin';

export const GlobalStyles = createGlobalStyle<{ theme: typeof sofaTheme }>`
    a, button {
        text-transform: uppercase !important;
        &.ant-btn-link {
          padding-left: 0 !important;
          font-weight: 400 !important;
        }
        &:disabled {
            border: none;
        }
        span {
            margin: 0 8px;
            font-size: ${({ theme }) => theme.fontSizeXxs}px;
        }
    }
    a {
        color: ${({ theme }) => theme.neutral8} !important;
    }

    button.ant-btn {
      height: 48px !important;
    }
    
    .ant-btn-default {
        border-color: ${({ theme }) => theme.primary6};
        color: ${({ theme }) => theme.primary8};
    }


    .ant-modal .ant-modal-content {
        border-radius: ${({ theme }) => theme.borderRadius};
      
        .ant-modal-confirm-body-wrapper {
            justify-content: center;

            &,.ant-modal-confirm-info {
                .anticon-info-circle {
                    display: none;
                }
                button {
                    border-radius: ${({ theme }) => theme.borderRadius};
                    background-color: ${({ theme }) => theme.neutral1};
                    border-color: ${({ theme }) => theme.primary8};
                    color: ${({ theme }) => theme.primary8};
            
                    &:not(:disabled):hover {
                        border-color: ${({ theme }) => theme.primary6};
                    }
                }
            }
            .ant-modal-confirm-body {
                justify-content: center;

                .ant-modal-confirm-content {
                    text-align: center;
                    color: ${({ theme }) => theme.neutral8};
                    font-size: ${({ theme }) => theme.fontSizeSm}px;
                    
                    svg {
                        display: block;
                        margin: 20px auto;
                        color: ${({ theme }) => theme.primary6};
                    }
                    div {
                        text-align: center;
                        color: ${({ theme }) => theme.neutral8};
                        font-size: ${({ theme }) => theme.fontSizeXxs}px;
                        line-height: ${({ theme }) => theme.fontSizeMd}px;
                    }
                }
            }
            .ant-modal-confirm-btns {
                text-align: center;
                margin-top: 32px;

                button{
                    outline: none;
                    line-height: 40px;
                    height: 48px;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        h1 {
            font-size: ${({ theme }) => theme.fontSizeMd}px !important;
        }
        .ant-modal {
            position: fixed;
            top: auto;
            left: 0;
            right:0;
            bottom: 0;
            width: auto !important;
            max-width: none !important;
            margin: 0;
            padding: 0;
        }
    }
`;

export const ModalStyled = styled<React.FC<ModalProps>>(Modal)`
    .ant-modal-content {
        text-align: center;
        padding: 50px 40px;
        max-width: 600px;

        .ant-modal-title {
            font-size: 30px;
        }
        .ant-modal-body {
            font-size: 18px;
            padding: 30px 0;
        }
        .ant-modal-close {
            display: none;
        }

        .ant-modal-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            button:last-child {
                margin-right: 30px;
            }

            button {
                flex: 1;
                margin-inline-start: 0 !important;
            }
        }

        @media (max-width: ${({ theme }) => theme.screenSm}) {
            max-width: none;
            padding: 30px 15px;

            .ant-modal-title {
                font-size: 20px;
            }
            .ant-modal-body {
                font-size: 16px;
                padding: 10px 0;
            }

            .ant-modal-footer {
                flex-direction: column-reverse;

                button {
                    margin: 10px 0 !important;
                    padding: 12px;
                }
            }
        }
    }
`;

export const SpinStyled = styled(Spin)`
    display: block;
    margin-top: 30vh;
`;
