import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { Stepper } from 'components';
import { STEPS, ROUTE_HOME, ROUTE_CHOOSE_STORE } from 'const';
import { useLangNavigate } from 'hooks';
import { selectEmail } from 'redux/selectors';

import { IconStyled, Wrapper, SpanStyled, ButtonStyled, TitleStyled, MailStyled } from './Confirmation.styled';

export const Confirmation = () => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const email = useSelector(selectEmail);

    const redirectHome = useCallback(() => {
        window.location.replace(ROUTE_HOME);
    }, []);

    useEffect(() => {
        !email && navigate(ROUTE_CHOOSE_STORE);
    }, [navigate, email]);

    return (
        <Wrapper>
            <Stepper step={STEPS.CONFIRMATION} />
            <div>
                <IconStyled name="success-filled" verticalAlign="text-top" />
                <TitleStyled>
                    {t('appointment_is')}
                    <SpanStyled>{t('confirmed')}</SpanStyled>
                </TitleStyled>
            </div>
            <Text>
                {t('received_by_email')}
                <MailStyled>{email}</MailStyled>
            </Text>
            <Title level={3}>{t('confirmation_title')}</Title>
            <ul>
                <li>
                    <Text>{t('confirmation_item_1')}</Text>
                </li>
                <li>
                    <Text>{t('confirmation_item_2')}</Text>
                </li>
                <li>
                    <Text>{t('confirmation_item_3')}</Text>
                </li>
            </ul>
            <Text>{t('confirmation_footer')}</Text>
            <ButtonStyled type="primary" size="large" onClick={redirectHome}>
                {t('go_homepage')}
            </ButtonStyled>
        </Wrapper>
    );
};
