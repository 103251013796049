import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';

import type { HumanResource, RootState } from 'models';

import { defaultState } from '../store/initialStates';

type VerificationState = RootState['verification'];

interface VerificationSlice extends SliceCaseReducers<VerificationState> {
    createAppointment: CaseReducer<VerificationState, PayloadAction<{ onSuccess: () => void; onError: () => void }>>;
    createAppointmentSuccess: CaseReducer<VerificationState>;
    createAppointmentFailure: CaseReducer<VerificationState>;
    setScheduleBlockTag: CaseReducer<VerificationState, PayloadAction<string>>;
    setCustomerUuid: CaseReducer<VerificationState, PayloadAction<string>>;
    getAvailableResources: CaseReducer<VerificationState, PayloadAction<{ onError: () => void }>>;
    getAvailableResourcesSuccess: CaseReducer<VerificationState, PayloadAction<HumanResource>>;
    getAvailableResourcesFailure: CaseReducer<VerificationState>;
    cleanUp: CaseReducer<VerificationState>;
    cleanUpSuccess: CaseReducer<VerificationState>;
    cleanUpFailure: CaseReducer<VerificationState>;
}

export const sliceVerification = createSlice<VerificationState, VerificationSlice>({
    initialState: defaultState.verification,
    name: '@@verification',
    reducers: {
        createAppointment: (state) => {
            state.loading = true;
        },
        createAppointmentSuccess: (state) => {
            state.loading = false;
        },
        createAppointmentFailure: (state) => {
            state.loading = false;
        },
        setScheduleBlockTag: (state, { payload }) => {
            state.blockTag = payload;
        },
        setCustomerUuid: (state, { payload }) => {
            state.customerUuid = payload;
        },
        getAvailableResources: (state) => {
            state.resourceLoading = true;
        },
        getAvailableResourcesSuccess: (state, { payload }) => {
            state.resourceLoading = false;
            state.resource = payload;
        },
        getAvailableResourcesFailure: (state) => {
            state.resourceLoading = false;
        },
        cleanUp: (state) => {
            state.isCleaningUp = true;
        },
        cleanUpSuccess: (state) => {
            state.isCleaningUp = false;
        },
        cleanUpFailure: (state) => {
            state.isCleaningUp = false;
        },
    },
});

export const verificationActions = sliceVerification.actions;

export const {
    createAppointment,
    createAppointmentSuccess,
    createAppointmentFailure,
    setScheduleBlockTag,
    setCustomerUuid,
    getAvailableResources,
    getAvailableResourcesSuccess,
    getAvailableResourcesFailure,
    cleanUp,
    cleanUpSuccess,
    cleanUpFailure,
} = sliceVerification.actions;
