import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import { Icon } from '@wynd-bo/keystone-icons';
import Geocode from 'react-geocode';

import { useActions } from 'hooks';
import { showNoServiceError } from 'utils';
import { entityActions } from 'redux/slices';
import {
    selectClosestEntityLoading,
    selectEntitiesLoading,
    selectEntityLoading,
    selectSelectedEntityId,
} from 'redux/selectors';
import { ReactComponent as NoLocation } from 'assets/no-location.svg';

import { AutocompleteStyled, IconCrossWrapper, IconWrapper, InputWrapper } from './Autocomplete.styled';

interface AutocompleteProps {
    isLoaded: boolean;
    noClosestStore: boolean;
    center?: google.maps.LatLngLiteral;
    setCenterMap: (coordinates: google.maps.LatLngLiteral | undefined) => void;
    place?: string;
    setPlace: (place: string) => void;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({ setCenterMap, place, setPlace }) => {
    const { t } = useTranslation();
    const loadingClosestEntity = useSelector(selectClosestEntityLoading);
    const loadingEntity = useSelector(selectEntityLoading);
    const loadingEntities = useSelector(selectEntitiesLoading);
    const selectedEntityId = useSelector(selectSelectedEntityId);
    const loading = loadingEntities || loadingClosestEntity || loadingEntity;
    const { getClosestEntity, enableLoadingClosestEntity, disableLoadingClosestEntity, resetEntity } =
        useActions(entityActions);
    const [isError, setIsError] = useState(false);

    const handleClick = () => {
        if (!place) return;
        enableLoadingClosestEntity();
        Geocode.fromAddress(place)
            .then(
                (response: any) => {
                    setIsError(false);
                    const { lat, lng } = response.results[0].geometry.location;
                    setCenterMap({ lat, lng });
                    getClosestEntity({ lat, lng });
                },
                () => {
                    setIsError(true);
                },
            )
            .catch(showNoServiceError)
            .finally(() => {
                disableLoadingClosestEntity();
            });
    };

    const clearInput = useCallback(() => {
        if (loading) return;
        setPlace('');
        setCenterMap(undefined);
        resetEntity();
    }, [loading, resetEntity, setCenterMap, setPlace]);

    const handleInput: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            setPlace(e?.target?.value || '');
        },
        [setPlace],
    );

    const showRefusedGeoModal = useCallback(() => {
        Modal.info({
            content: (
                <>
                    <NoLocation />
                    <div>{t('you_refused_geolocation')}</div>
                </>
            ),
            okText: t('close'),
        });
    }, [t]);

    const getLocation = useCallback(() => {
        if (loading) return;
        if (!navigator.geolocation) {
            showRefusedGeoModal();
            return;
        }
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setCenterMap(location);
                Geocode.fromLatLng(String(position.coords.latitude), String(position.coords.longitude))
                    .then(
                        (response: any) => {
                            const address = response.results[0].formatted_address;
                            setPlace(address);
                        },
                        () => {
                            setIsError(true);
                        },
                    )
                    .catch(showNoServiceError);
            },
            () => {
                showRefusedGeoModal();
            },
        );
    }, [loading, showRefusedGeoModal, setCenterMap, setPlace]);

    return (
        <AutocompleteStyled>
            <InputWrapper>
                <Input
                    value={place}
                    onChange={handleInput}
                    disabled={!!selectedEntityId}
                    placeholder={`${t('enter_address')}*`}
                    suffix={
                        place ? (
                            <IconCrossWrapper onClick={clearInput}>
                                <Icon name="cross-filled" size="sm" />
                            </IconCrossWrapper>
                        ) : (
                            <IconWrapper onClick={getLocation}>
                                <Icon name="my-location" size="sm" />
                            </IconWrapper>
                        )
                    }
                    status={isError ? 'error' : ''}
                    size="large"
                />
                {isError && <Text type="danger">{t('enter_another_department')}</Text>}
            </InputWrapper>
            <Button
                type="primary"
                icon={<Icon name="search" size="sm" verticalAlign="text-bottom" />}
                size="large"
                loading={loadingClosestEntity}
                disabled={loading || !!selectedEntityId}
                onClick={handleClick}
            >
                {t('search')}
            </Button>
        </AutocompleteStyled>
    );
};
