import { call, put, takeLatest } from 'redux-saga/effects';

import { mapEntities, searchAccessor, showNoServiceError } from 'utils';
import type { EntityFull } from 'models';
import { DEFAULT_MAP_CENTER } from 'const';

import { getEntities, getEntitiesFailure, getEntitiesSuccess } from '../slices';

function* getEntitiesSaga() {
    try {
        const entities: EntityFull[] = yield call(() =>
            searchAccessor.getClosestEntity({
                latitude: DEFAULT_MAP_CENTER.lat,
                longitude: DEFAULT_MAP_CENTER.lng,
                distance: 5000,
            }),
        );

        yield put(getEntitiesSuccess(mapEntities(entities)));
    } catch (error) {
        yield put(getEntitiesFailure());
        yield call(showNoServiceError);
    }
}

export default function* watcher() {
    yield takeLatest(getEntities.type, getEntitiesSaga);
}
