import { AbstractAccessor } from 'sdkore';

export class HumansAccessor extends AbstractAccessor {
    constructor() {
        super('/public/booking_management/humans');
    }

    public async getAnonymousAvailabilities(start: string, end: string, entity: number, assignment: string) {
        const query = await this.buildQueryString({
            start,
            end,
            entity,
            assignment: [assignment],
        });

        return this.client.get(`${this.route}/availabilities/anonymous?${query}`);
    }
}
