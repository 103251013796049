import styled from 'styled-components';
import { Icon } from '@wynd-bo/keystone-icons';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Button } from 'antd';

export const IconSuccess = styled(Icon)`
    color: ${({ theme }) => theme.valid6};
    width: 48px !important;
    height: 48px !important;
    transform: translate(-4px, -6px);

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 43px !important;
        height: 43px !important;
        margin-top: 0;
        margin-right: 6px !important;
        transform: translate(-1px, -7px);
    }
`;

export const IconError = styled(Icon)`
    color: ${({ theme }) => theme.error6};
    width: 48px !important;
    height: 48px !important;
    transform: translate(-4px, -6px);

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 43px !important;
        height: 43px !important;
        margin-top: 0;
        margin-right: 6px !important;
        transform: translate(-1px, -7px);
    }
`;

export const Wrapper = styled.div`
    max-width: 666px;
    margin: 0 auto;
    padding: 62px 50px 60px;

    .ant-divider {
        border-block-start: 1px solid ${({ theme }) => theme.neutral3};
        margin: 17px 0;
    }

    .appointment-duration {
        margin-top: 8px;
    }
    .entity-info {
        margin-right: 48px;

        .phone {
            margin-top: 4px;

            a {
                text-decoration: underline;
            }
        }
    }
    .entity-info,
    .entity-hours {
        h4 {
            margin-top: 15px;
        }
    }
    .customer-info {
        margin-bottom: 8px;
    }
    .comment {
        div {
            font-style: italic;
            line-height: 24px;

            &::after,
            &::before {
                content: '”';
                display: inline;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        width: 100%;
        padding: 28px 0;

        .ant-divider {
            margin: 26px 0 17px;
        }
        .appointment-date-time h4 {
            margin-bottom: 13px;
        }

        .appointment-duration {
            margin-bottom: 0;

            h4 {
                line-height: 24px;
            }
        }
        .entity-hours .ant-space-item > span {
            justify-content: flex-start;
        }
        .customer-info {
            margin-top: -3px;
            margin-bottom: 0;

            h4 {
                line-height: 22px;
            }
        }
        .comment {
            margin-top: 5px;

            h4 {
                line-height: 22px;
            }
        }
    }
`;

export const TitleWrapper = styled.div`
    display: flex;

    svg {
        margin-right: 7px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        svg {
            margin-right: 10px;
        }
        h1 {
            line-height: 26px;
        }
    }
`;

export const StyledTitle = styled(Title)`
    margin-top: 0;

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-bottom: 24px !important;
        font-size: ${({ theme }) => theme.fontSizeMd}px !important;
    }
`;

export const StyledSubtitle = styled(Text)`
    color: ${({ theme }) => theme.neutral6};
`;

export const SuccessText = styled.span`
    color: ${({ theme }) => theme.valid6};
`;

export const ErrorText = styled.span`
    color: ${({ theme }) => theme.error6};
`;

export const AppointmentActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    button {
        background-color: ${({ theme }) => theme.primary6};
        height: 48px;
        padding: 4px 24px;
        display: flex;
        align-items: center;
    }

        span {
            margin: 0 0 0 8px;
        }
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        button {
            width: 100%;
        }
    }
`;

export const EntityWrapper = styled.div`
    display: flex;

    @media (max-width: ${({ theme }) => theme.screenXs}) {
        flex-direction: column;
    }
`;

export const CancelButton = styled(Button)`
    & {
        border: 1px solid ${({ theme }) => theme.primary6};
        background-color: ${({ theme }) => theme.neutral1} !important;
        color: ${({ theme }) => theme.primary8};
        border-radius: 0;

        &:hover {
            background-color: ${({ theme }) => theme.primary6} !important;
            color: ${({ theme }) => theme.neutral1};
        }
    }
`;

export const AppointmentButton = styled(Button)`
    & {
        border: 1px solid ${({ theme }) => theme.primary6};
        background-color: ${({ theme }) => theme.primary6} !important;
        color: ${({ theme }) => theme.neutral1};
        border-radius: 0;
        padding: 4px 24px 4px 21px !important;

        &:hover {
            background-color: ${({ theme }) => theme.neutral1} !important;
            color: ${({ theme }) => theme.primary8} !important;
        }

        span {
            margin: 0 0 0 4px !important;
        }
    }
`;
