export interface Resource {
    id: number;
    uuid: string;
    name: string;
    label: string;
    bookable: boolean;
    external_uuid: null;
    resource_type: { id: number; uuid: string; name: string };
    parent: null;
    status: { id: number; uuid: string; code: string; title: string };
    entities: [{ id: number }];
    resource_param_values: {
        id: number;
        uuid: string;
        value: string;
        param: { id: number; uuid: string; name: string };
    }[];
    resource_availabilities: [];
    children?: Resource[];
    created_at: string;
    updated_at: string;
    resource_steps: [];
    compatible_assignments: [];
    groups: [];
    planning_templates: [{ id: number; uuid: string; type: string; state: string }];
    order: null;
    assignment_categories: [];
    state: string;
}

export const enum TypeUuids {
    SHOP = '690c386a-4af4-4a4d-bf1a-ceab69fd3da0',
    ASSIGNMENT = '16730f65-a984-439a-b900-bfc96dea5b83',
}

export interface ResourcesAvailability {
    uuid: string;
    start_at: string;
    end_at: string;
    resource: { uuid: string; name: string; label: string };
    template: {
        uuid: string;
        name: string;
        label: string;
    };
    timezone: string;
}

export interface HumanResource {
    uuid: string;
    name: string;
    label: string;
}

export interface AvailableResourcesResponse {
    available: HumanResource[];
    unavailable: HumanResource[];
}

export const enum StatusTypes {
    OPEN = 'b3bb2225-2d42-429e-b88a-fa0737f0bc91',
    CANCELED = '9899416a-10ee-440b-8752-6fc395f6645d',
}

export interface Schedule {
    booking: {
        appointment: boolean;
        uuid: string;
        name: string;
        label: string;
        user: {
            id: number;
            uuid: string;
            created_at: string;
            updated_at: string;
        };
        resource: {
            uuid: string;
            name: string;
            label: string;
            bookable: boolean;
            external_uuid: null;
            resource_type: {
                uuid: string;
                name: string;
                label: string;
                status: { uuid: string };
            };
            status: { uuid: string };
            entities: [{ id: number; uuid: string; parent: { id: number }; country: { id: number } }];
            resource_param_values: {
                uuid: string;
                value: string;
                param: {
                    uuid: string;
                    name: string;
                    label: string;
                };
            }[];
            order: null;
            state: string;
        };
        status: { uuid: string; code: BookingStatus };
        customer: null | {
            id: number;
            uuid: string;
            is_catering: boolean;
            lastname: string;
            firstname: string;
            honorific_prefix: null | string;
            email: string;
            phone: string;
        };
        created_at: string;
        updated_at: string;
        booking_details: {
            uuid: string;
            start_at: string;
            end_at: string;
            exclusivity: boolean;
            capacity_percent_min: 0;
            capacity_percent_max: 0;
            started_at: null;
            ended_at: null;
            resource: {
                uuid: string;
                name: string;
                label: string;
                bookable: boolean;
                external_uuid: null;
                resource_type: {
                    uuid: string;
                    name: string;
                    label: string;
                    status: { uuid: string };
                };
                status: { uuid: string };
                entities: {
                    id: number;
                    uuid: string;
                    parent: { id: number };
                    country: { id: number };
                }[];
                resource_param_values: {
                    uuid: string;
                    value: string;
                    param: {
                        uuid: string;
                        name: string;
                        label: string;
                    };
                }[];
                order: null;
                state: string;
            };
            status: { uuid: string };
            ready_before: number;
            state: string;
            timezone: string;
        }[];
        block_tag: string;
        max_book_date: string;
        comment: null;
        user_comment: null;
        preparation_due_time: null;
        entity: { id: number; uuid: string; parent: { id: number }; country: { id: number } };
        resource_quantity: null;
        product_volume: null;
        duplicate_uuid: null;
        unlimited: boolean;
        planning_repetition_rule: null;
        max_book_interval: null;
        template_uuid: null;
        linked_order_uuid: null;
        linked_order_reference: null;
        state: string;
    };
    quantity: number;
    reservation: number;
}

export interface BookingShort {
    blockTag: string;
    start_at: string;
    end_at: string;
    resource: HumanResource;
    duration: number;
    customer: {
        lastname: string;
        firstname: string;
        email: string;
        phone: string;
        comment: string;
    };
    entity: {
        id: number;
    };
    status: BookingStatus;
    uuid: string;
}

export const enum BookingStatus {
    BOOKED = 'booked',
    CANCELLED = 'cancelled',
}
