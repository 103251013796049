import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Icon } from '@wynd-bo/keystone-icons';
import dayjs from 'dayjs';

import { DAY_NAME, ROUTE_DATE_AND_HOUR } from 'const';
import { capitalize } from 'utils';
import { useLangNavigate } from 'hooks';

import { AppointmentDateTime, AppointmentDuration, ButtonStyled } from './AppointmentInfo.styled';

interface AppointmentInfoProps {
    saveForm?: () => void;
    showButton?: boolean;
    start_at: dayjs.Dayjs;
    duration: number;
}

export const AppointmentInfo: React.FC<AppointmentInfoProps> = ({
    saveForm = () => {},
    showButton,
    start_at,
    duration,
}) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();

    const goBack = useCallback(() => {
        saveForm();
        navigate(ROUTE_DATE_AND_HOUR);
    }, [navigate, saveForm]);

    if (!start_at || !duration) return null;

    return (
        <>
            <AppointmentDateTime className="appointment-date-time">
                <Title level={4}>{t('your_appointment_planned_for')}</Title>
                <Title level={3}>
                    {`${capitalize(start_at.format(DAY_NAME))} ${start_at.format('D')} ${capitalize(
                        start_at.format('MMMM'),
                    )} ${t('at')} ${start_at.format('H')} h ${start_at.format('mm')}`}
                </Title>
            </AppointmentDateTime>
            {showButton && (
                <ButtonStyled
                    size="large"
                    type="link"
                    onClick={goBack}
                    icon={<Icon size="sm" name="chevron-left" verticalAlign="text-bottom" />}
                >
                    {t('change_hours')}
                </ButtonStyled>
            )}
            <AppointmentDuration className="appointment-duration">
                <Title level={4}>{t('expected_duration')}</Title>
                <Text>{t('expected_duration_start') + duration} min</Text>
            </AppointmentDuration>
        </>
    );
};
