import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';
import type { ContactInformation, RootState } from 'models';

import { defaultState } from '../store/initialStates';

type ContactInfo = RootState['contactInfo'];

interface ContactInfoSlice extends SliceCaseReducers<ContactInfo> {
    setContactInfo: CaseReducer<ContactInfo, PayloadAction<ContactInformation>>;
    clearContactInfo: CaseReducer<ContactInfo>;
}

export const sliceContactInfo = createSlice<ContactInformation, ContactInfoSlice>({
    initialState: defaultState.contactInfo,
    name: '@@contactInfo',
    reducers: {
        setContactInfo: (state, { payload }) => payload,
        clearContactInfo: () => defaultState.contactInfo,
    },
});

export const contactInfoActions = sliceContactInfo.actions;

export const { setContactInfo, clearContactInfo } = sliceContactInfo.actions;
