import { PayloadAction, createSlice, SliceCaseReducers, CaseReducer } from '@reduxjs/toolkit';
import type { Entity, RootState } from 'models';

import { defaultState } from '../store/initialStates';

type EntitiesState = RootState['entities'];

interface EntitiesSlice extends SliceCaseReducers<EntitiesState> {
    getEntities: CaseReducer<EntitiesState>;
    getEntitiesSuccess: CaseReducer<EntitiesState, PayloadAction<Entity[]>>;
    getEntitiesFailure: CaseReducer<EntitiesState>;
    setEntityIsChosen: CaseReducer<EntitiesState, PayloadAction<number | undefined>>;
    resetHoveredEntity: CaseReducer<EntitiesState>;
}

export const sliceEntities = createSlice<EntitiesState, EntitiesSlice>({
    initialState: defaultState.entities,
    name: '@@entities',
    reducers: {
        getEntities: (state) => {
            state.loading = true;
        },
        getEntitiesSuccess: (state, { payload }) => {
            state.loading = false;
            state.list = payload;
        },
        getEntitiesFailure: (state) => {
            state.loading = false;
        },
        setEntityIsChosen: (state, { payload }) => {
            state.hoveredEntity = payload;
        },
        resetHoveredEntity: (state) => {
            state.hoveredEntity = undefined;
        },
    },
});

export const entitiesActions = sliceEntities.actions;

export const { getEntities, getEntitiesSuccess, getEntitiesFailure, setEntityIsChosen, resetHoveredEntity } =
    sliceEntities.actions;
