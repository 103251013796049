import React, { FC } from 'react';

import type { StringInterval } from 'models';
import { capitalize } from 'utils';

import { StyledDay, StyledSlots, StyledText } from './DayHours.styled';

interface DayHoursProps {
    day: string;
    hours: StringInterval[];
}

export const DayHours: FC<DayHoursProps> = ({ day, hours }) => (
    <StyledText data-testid={`${day}-hours-root`}>
        <StyledDay>{capitalize(day)}</StyledDay>
        <StyledSlots>
            {hours.map(({ start_at, end_at }) => (
                <span key={start_at}>{`${start_at} - ${end_at}`}</span>
            ))}
        </StyledSlots>
    </StyledText>
);
