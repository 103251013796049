import { AbstractAccessor } from 'sdkore';

export interface VerifyResponse {
    success: boolean;
    challenge_ts?: string; // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
    hostname?: string; // the hostname of the site where the reCAPTCHA was solved
    score?: string;
    action?: string;
    'error-codes': string[]; // optional, see https://developers.google.com/recaptcha/docs/verify#error_code_reference
}

export class ReCaptachaAccessor extends AbstractAccessor {
    constructor() {
        super('/public/captcha/verify');
    }

    public async verifyReCaptachaToken(token: string) {
        return this.client.post(`${this.route}`, { token });
    }
}
