import { AbstractAccessor } from 'sdkore';

import type { EntityParams } from 'models';

export class SearchAccessor extends AbstractAccessor {
    constructor() {
        super('/public/entities/search/geoloc');
    }

    public async getClosestEntity(params: Partial<EntityParams>) {
        const query = await this.buildQueryString(params);

        return this.handleLastResponse(await this.client.get(`${this.route}?${query}`)).data;
    }
}
