import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

export const AutocompleteStyled = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;

    button {
        margin: 0 16px;
    }
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;

        button {
            margin: 16px 0;
        }
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    margin-bottom: 20px;

    .ant-select-auto-complete {
        width: 100%;
    }

    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:hover {
        border-width: 2px;
        height: 48px;
        border-inline-end-width: 2px;
        border-radius: ${({ theme }) => theme.borderRadius}px;
        border-color: ${({ theme }) => theme.neutral5};
    }
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus {
        border-color: ${({ theme }) => theme.secondary6};
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        margin-bottom: 22px;
        .ant-input:placeholder-shown {
            font-size: 13px !important;
        }
    }
`;

export const ListStyled = styled.ul`
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.neutral1};
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    border-radius: ${({ theme }) => theme.borderRadius}px;
    box-shadow: 0 1px 2px rgba(88, 115, 181, 0.1), 0 8px 15px rgba(88, 115, 181, 0.15);

    li {
        list-style-type: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        padding: 8px 16px;
    }
    li:hover {
        background-color: ${({ theme }) => theme.secondary1};
    }
`;

export const TextStyled = styled(Text)`
    position: absolute;
    top: 40px;
    left: 0;
`;

export const IconWrapper = styled.div`
    cursor: pointer;
    line-height: 16px;
`;

export const IconCrossWrapper = styled.div`
    cursor: pointer;
    line-height: 16px;
    color: ${({ theme }) => theme.neutral5};
`;
