import { createSelector } from '@reduxjs/toolkit';

import { DEFAULT_TIMEZONE } from 'const';
import type { RootState } from 'models';
import { mapCoordinates } from 'utils';

export const selectEntity = ({ entity }: RootState) => entity;
export const selectEntityId = ({ entity }: RootState) => entity.id;
export const selectEntityResults = ({ entity }: RootState) => entity.results;
export const selectEntityCoordinates = ({ entity }: RootState) => entity.coordinates;
export const selectSelectedEntity = ({ entity }: RootState) => entity.selectedEntity;
export const getClosestEntity = ({ entity }: RootState) => entity.closestEntities;
export const selectEntityLoading = ({ entity }: RootState) => entity.loading;
export const selectEntityNext = ({ entity }: RootState) => entity.next;
export const selectClosestEntityLoading = ({ entity }: RootState) => entity.loadingClosestEntity;
export const selectSelectedShopUuid = ({ entity }: RootState) => entity.selectedShopUuid;
export const selectSelectedEntityUuid = ({ entity }: RootState) => entity.selectedEntity?.uuid;
export const selectSelectedEntityId = ({ entity }: RootState) => entity.selectedEntity?.id;
export const selectTimezone = ({ entity }: RootState): string => entity.selectedEntity?.timezone || DEFAULT_TIMEZONE;

export const getEntitiesCoordinates = createSelector(getClosestEntity, (entities) => mapCoordinates(entities));
