import { Icon } from '@wynd-bo/keystone-icons';
import Button from 'antd/lib/button';
import Title from 'antd/lib/typography/Title';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { StyledCalendar } from '../Calendar/Calendar.styled';
import {
    selectAvailabilities,
    selectSelectedEntity,
    selectSelectedShopUuid,
    selectCalendarLoading,
} from 'redux/selectors';
import { CalendarHeader, CalendarWrapper, DateCell } from './AvailabilitiesCalendar.styled';
import { DAY_MONTH_YEAR } from 'const';
import { calendarActions } from 'redux/slices';
import { useActions } from 'hooks';
import { getFirstAvailableDate, isDayOutOfMoth } from 'utils';

export const AvailabilitiesCalendar: FC = () => {
    const [monthCounter, setMonthCounter] = useState(1);
    // Avoid infinite loop if no availabilities in the future
    const LIMIT = 6;

    const [date, setDate] = useState<Dayjs>();
    const [visibleDate, setVisibleDate] = useState<Dayjs>(dayjs.tz(new Date()));

    const availabilities = useSelector(selectAvailabilities);
    const entity = useSelector(selectSelectedEntity);
    const shopUuid = useSelector(selectSelectedShopUuid);
    const loading = useSelector(selectCalendarLoading);

    const { getAvailabilities, setSlots, clearCalendar } = useActions(calendarActions);

    const isDateDisabled = useCallback(
        (value: Dayjs) => {
            if (value.isBefore(dayjs(), 'day')) return true;

            const valueKey = value.format(DAY_MONTH_YEAR);
            return !availabilities?.[valueKey];
        },
        [availabilities],
    );

    useEffect(() => {
        const availableDate = getFirstAvailableDate(availabilities);
        setDate(availableDate ? dayjs.tz(new Date(availableDate)) : undefined);
        if (!availableDate && !loading && monthCounter <= LIMIT) {
            setVisibleDate(visibleDate.add(1, 'month'));
            setMonthCounter(monthCounter + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilities, loading]);

    const shouldDisablePrevMonth = useMemo(() => {
        return !!visibleDate && visibleDate.isSame(dayjs(), 'month');
    }, [visibleDate]);

    const dateFullCellRender = useCallback(
        (value: Dayjs) => {
            if (isDayOutOfMoth(value, visibleDate)) return null;

            const isSelected = !!date && value.isSame(date, 'day');
            return (
                <DateCell onClick={() => setDate(value)} isSelected={isSelected}>
                    {value.date()}
                </DateCell>
            );
        },
        [date, visibleDate],
    );

    const clear = useCallback(() => {
        setDate(undefined);
        setSlots({});
    }, [setSlots]);

    useEffect(() => {
        if (!shopUuid || !entity?.uuid) return;
        getAvailabilities({ visibleDate: visibleDate.format(), date: date?.format() });
    }, [date, entity?.uuid, getAvailabilities, shopUuid, visibleDate]);

    useEffect(() => {
        return () => {
            clearCalendar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = useCallback((value: Dayjs) => {
        setVisibleDate(dayjs.tz(value));
    }, []);

    return (
        <CalendarWrapper>
            <StyledCalendar
                fullscreen={false}
                value={visibleDate}
                onChange={onChange}
                dateFullCellRender={dateFullCellRender}
                disabledDate={isDateDisabled}
                headerRender={(config) => (
                    <CalendarHeader>
                        <Button
                            type="link"
                            onClick={() => {
                                config.onChange(config.value.subtract(1, 'month'));
                                clear();
                            }}
                            disabled={shouldDisablePrevMonth}
                        >
                            <Icon name="chevron-left" />
                        </Button>
                        <Title level={3}>{config.value.format('MMMM YYYY')}</Title>
                        <Button
                            type="link"
                            onClick={() => {
                                config.onChange(config.value.add(1, 'month'));
                                clear();
                            }}
                        >
                            <Icon name="chevron-right" />
                        </Button>
                    </CalendarHeader>
                )}
            />
        </CalendarWrapper>
    );
};
