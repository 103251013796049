import Button, { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';

export const StyledEntityDetailed = styled.div``;

export const EntityWrapper = styled.div`
    display: flex;
    background: ${({ theme }) => theme.neutral2};
    padding: 8px 24px 16px 16px;
    cursor: pointer;
    position: relative;
    h4 {
        margin-top: 0;
        line-height: 32px;
    }

    @media (max-width: ${({ theme }) => theme.screenSm}) {
        flex-direction: column;

        .ant-space {
            margin-top: 14px;
        }
    }
`;

export const ButtonStyled = styled<React.FC<ButtonProps>>(Button)`
    position: absolute;
    left: 50px;
    bottom: 50px;
    @media (max-width: ${({ theme }) => theme.screenSm}) {
        display: none;
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 24px;
    top: calc(50% - 16px);
`;
