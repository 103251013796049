import Space from 'antd/lib/space';

import { SkeletonButton, SkeletonInput, Wrapper } from './SkeletonTime.styled';

export const SkeletonTime = () => (
    <>
        <Wrapper>
            <SkeletonInput active size="small" />
            <Space>
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
            </Space>
        </Wrapper>
        <Wrapper>
            <SkeletonInput active size="small" />
            <Space>
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
            </Space>
        </Wrapper>
        <Wrapper>
            <SkeletonInput active size="small" />
            <Space>
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
                <SkeletonButton size="large" active />
            </Space>
        </Wrapper>
    </>
);
