import styled from 'styled-components';
import FakeButton from 'antd/lib/skeleton/Button';
import FakeInput from 'antd/lib/skeleton/Input';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    div {
        flex-wrap: wrap;
    }
`;

export const SkeletonButton = styled(FakeButton)`
    height: 35px;
    margin: 5px;

    span {
        width: 140px !important;
    }
`;

export const SkeletonInput = styled(FakeInput)`
    margin: 5px;
`;
