import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import type { RootState } from 'models';

import { reducers } from '../slices';
import rootSaga from '../sagas';
import { defaultState as preloadedState } from './initialStates';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore<RootState>({
    reducer: reducers,
    preloadedState,
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
